import Vue from 'vue';
import i18n from '@/i18n';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { PhoneNumberUtil } from 'google-libphonenumber';
import * as libStdnum from 'stdnum';
import axios from 'axios';
import JsPDF from 'jspdf';
import { elementToSVG } from 'dom-to-svg';
import jsQR from 'jsqr';
import * as png from 'upng-js';
import store from '../store/store';
import router from '../router';
import request from './access/request';
import user from './user/user';
import date from './date/date';
import 'svg2pdf.js';
import 'jspdf-autotable';

const phoneUtil = PhoneNumberUtil.getInstance();

const util = {
    request: request,
    /**
     * Método responsável por abrir novas abas ou modais
     *
     * Exemplos de utilização:
     * $util.openPage('MinhaPagina')
     * $util.openPage({ name: 'MinhaPagina' })
     * $util.openPage('MinhaPagina', { props: { ID: 5 } })
     *
     * @param page : Sting|Object (Nome da página que deseja abrir)
     * @param config : Object (Configurações da página: props, eventos, titulo, etc)
     */
    openPage: function (page, config = {}) {
        const { props, events, dialog } = config;
        let { isDialog } = config;

        if (typeof page === 'string') {
            page = { name: page };
        }

        // Keeps its default name for future refrence
        page.componentName = page.name;
        const route = router.currentRoute;
        // If its about to show only content, There are no tabs. If the user intention is to change pages, it always opens as a dialog
        if (route.query && route.query.onlycontent && store.getters.getPages.length > 0) {
            isDialog = true;
        }
        this.showLoading();
        store.dispatch('OPEN_PAGE', {
            page: page, isDialog: isDialog, dialog: dialog, props: props, events: events,
        });
    },
    /**
     * Método responsável por fechar a página/ modal atual
     */
    closePage: function () {
        store.dispatch('HANDLER_CLOSE_PAGE');
    },
    // /**
    //  * @deprecated - NÃO USAR
    //  * Método responsável por fechar a página/ modal atual e abrir página informada
    //  *
    //  * @param page
    //  * @param config
    //  */
    // closePageAndOpenPage: function (page, config = {}) {
    //     if (config.bypassEvents) {
    //         const events = Object.entries(config.bypassEvents).filter(([key]) => !key.startsWith('hook:') && !key.startsWith('shortcuts')).map(([key, value]) => {
    //             let event;
    //             if (typeof value === 'function') {
    //                 event = value;
    //             } else if (Array.isArray(value) && value.length) {
    //                 event = value[0];
    //             }
    //             return { name: key, callback: event };
    //         });
    //         if (config.events) {
    //             config.events = [
    //                 ...config.events,
    //                 ...events,
    //             ];
    //         } else {
    //             config.events = events;
    //         }
    //     }
    //
    //     this.closePage();
    //
    //     this.openPage(page, config);
    // },
    /**
     * Exibir mensagem de Loading FullScreen. Lembre de utilizar a função para esconder esta mensagem.
     * Após 'X' segundos esta mensagem é escondida por padrão.
     */
    showLoading: function () {
        store.dispatch('SHOW_LOADING');
    },
    /**
     * Esconder mensagem de Loading
     */
    hideLoading: function () {
        store.dispatch('HIDE_LOADING');
    },
    getLoggedUser: function () {
        return user.getLoggedUser();
    },
    enableChat: function (enable) {
        return user.enableChat(enable);
    },
    numberFormat: function (number, options) {
        if (number != null && typeof number === 'number' && !Number.isNaN(number)) {
            options = options || {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            };
            return number.toLocaleString(i18n.locale.replace('_', '-'), options);
        }
        return '';
    },
    toNumber: function (number) {
        if (number === null || number === undefined) {
            return '';
        }
        return number.toString().replace(',', '.');
    },
    validateURL: function (url) {
        if (!url || !url.trim()) {
            return false;
        }

        /* eslint-disable-next-line no-useless-escape */
        const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+$/;
        return regex.test(url);
    },
    numberView: function (number) {
        if (number === null || number === undefined) {
            return '';
        }
        return number.toString().replace('.', ',');
    },
    formatTag: function (item) {
        if (item.NUMERO_IDENTIFICACAO) {
            return item.NUMERO_IDENTIFICACAO;
        }
        if (item.ID_PONTO_SERVICO) {
            return `*${item.ID_PONTO_SERVICO}`;
        }
        return i18n.t('UNKNOWN');
    },
    formatAddress: function (item) {
        let address = '';
        if (item) {
            if (item.DESC_TIPO_LOGRADOURO) {
                address = item.DESC_TIPO_LOGRADOURO;
                if (item.NOME_LOGRADOURO) {
                    address += ` ${item.NOME_LOGRADOURO}`;
                }
            } else if (item.ENDERECO) {
                address = item.ENDERECO;
            } else if (item.ENDERECO_LIVRE) {
                address = item.ENDERECO_LIVRE;
            } else if (item.NOME_LOGRADOURO_COMPLETO) {
                address = item.NOME_LOGRADOURO_COMPLETO;
            }
            const initialLocalNumber = item.NUMERO_LOCAL_INICIAL || item.NUMERO_LOCAL;
            if (initialLocalNumber || parseInt(initialLocalNumber, 10) === 0) {
                address += `, ${initialLocalNumber}`;
            }
            if (item.NUMERO_LOCAL_FINAL || parseInt(item.NUMERO_LOCAL_FINAL, 10) === 0) {
                address += `-${item.NUMERO_LOCAL_FINAL}`;
            }
            if (item.REFERENCIA_COMPLEMENTAR && item.REFERENCIA_COMPLEMENTAR.trim() !== '') {
                address += ` (${item.REFERENCIA_COMPLEMENTAR})`;
            }
            if (item.CEP && item.CEP.trim() !== '' && !address.includes(item.CEP)) {
                address += ` - ${this.maskCep(item.CEP)}`;
            }
        }
        return address;
    },
    formatAddressWithDistrict: function (item) {
        let address = '';
        if (item) {
            if (item.NOME_LOGRADOURO_COMPLETO) {
                address = item.NOME_LOGRADOURO_COMPLETO;
                if (item.NOME_BAIRRO) {
                    address += `, ${item.NOME_BAIRRO}`;
                }
            }
        }
        return address;
    },
    formatLocation: function (item) {
        if (item == null) {
            return '';
        }

        let value = '';
        const city = this.formatCity(item);
        const region = item.NOME_REGIAO;
        const district = item.NOME_BAIRRO;

        if (city) {
            value += city;
        }
        if (region && region !== 'Geral') {
            value += ` - ${region}`;
        }
        if (district) {
            value += ` - ${district}`;
        }

        return value;
    },
    formatCity: function (item) {
        let value = '';
        if (item && item.NOME_MUNICIPIO) {
            value = item.NOME_MUNICIPIO;

            if (item.SIGLA_UF) {
                value += `-${item.SIGLA_UF}`;
            }
        }
        return value;
    },
    comparatorNumber: function (a, b) {
        return Number(a) === Number(b);
    },
    // TODO: Remover e substituir componentes por switch
    comparatorYesNo: function (a, b) {
        return !a === !b;
    },
    clearPropNullObject: function (obj) {
        Object.keys(obj).forEach(key => {
            if (obj[key] === null || obj[key] === undefined) {
                delete obj[key];
            }
        });
    },
    xmlToJson: function (xml) {
        let obj = {};
        if (xml.nodeType === 1) {
            if (xml.attributes.length > 0) {
                obj['@attributes'] = {};
                for (let j = 0; j < xml.attributes.length; j++) {
                    const attribute = xml.attributes.item(j);
                    obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
                }
            }
        } else if (xml.nodeType === 3) {
            obj = xml.nodeValue;
        }
        if (xml.hasChildNodes()) {
            for (let i = 0; i < xml.childNodes.length; i++) {
                const item = xml.childNodes.item(i);
                const { nodeName } = item;
                if (typeof (obj[nodeName]) === 'undefined') {
                    obj[nodeName] = this.xmlToJson(item);
                } else {
                    if (typeof (obj[nodeName].push) === 'undefined') {
                        const old = obj[nodeName];
                        obj[nodeName] = [];
                        obj[nodeName].push(old);
                    }
                    obj[nodeName].push(this.xmlToJson(item));
                }
            }
        }
        return obj;
    },
    // TODO: Tradução do nome das chaves: $t
    keyNameFromShortkey: function (shortkey) {
        if (/[a-z]/g.test(shortkey)) {
            return shortkey.toUpperCase();
        }
        if (/[0-9]/g.test(shortkey)) {
            return shortkey;
        }
        if (/F1+[0-9]/g.test(shortkey)) {
            return shortkey;
        }
        if (shortkey === 'del') {
            return 'Delete';
        }
        if (shortkey === 'backspace') {
            return 'Backspace';
        }
        if (shortkey === 'insert') {
            return 'Insert';
        }
        if (shortkey === 'numlock') {
            return 'NumLock';
        }
        if (shortkey === 'capslock') {
            return 'CapsLock';
        }
        if (shortkey === 'pause') {
            return 'Pause';
        }
        if (shortkey === 'contextmenu') {
            return 'ContextMenu';
        }
        if (shortkey === 'scrolllock') {
            return 'ScrollLock';
        }
        if (shortkey === 'browserhome') {
            return 'BrowserHome';
        }
        if (shortkey === 'mediaselect') {
            return 'MediaSelect';
        }
        if (shortkey === 'shift') {
            return 'Shift';
        }
        if (shortkey === 'ctrl') {
            return 'Control';
        }
        if (shortkey === 'alt') {
            return 'Alt';
        }
        if (shortkey === 'altgraph') {
            return 'Alt Graph';
        }
        if (shortkey === 'meta') {
            return 'Windows(Mac)';
        }
        if (shortkey === 'arrowup') {
            return 'Arrow Up';
        }
        if (shortkey === 'arrowdown') {
            return 'Arrow Down';
        }
        if (shortkey === 'arrowleft') {
            return 'Arrow Left';
        }
        if (shortkey === 'arrowright') {
            return 'Arrow Right';
        }
        if (shortkey === 'enter') {
            return 'Enter';
        }
        if (shortkey === 'esc') {
            return 'Escape';
        }
        if (shortkey === 'tab') {
            return 'Tab';
        }
        if (shortkey === 'space') {
            return 'Space';
        }
        if (shortkey === 'pageup') {
            return 'Page Up';
        }
        if (shortkey === 'pagedown') {
            return 'Page Down';
        }
        if (shortkey === 'home') {
            return 'Home';
        }
        if (shortkey === 'end') {
            return 'End';
        }
        return '';
    },
    formatRolesKey: function (key) {
        if (key) {
            if (String(key) === '0') {
                return i18n.t('CLIENT');
            }
            if (String(key) === '1') {
                return i18n.t('CONTRIBUTOR');
            }
            if (String(key) === '2') {
                return i18n.t('CONCURRENT');
            }
            if (String(key) === '3') {
                return i18n.t('MANUFACTURER');
            }
            if (String(key) === '4') {
                return i18n.t('PROVIDER');
            }
            if (String(key) === '5') {
                return i18n.t('SHIPPING_COMPANY');
            }
            if (String(key) === '6') {
                return i18n.t('USER');
            }
        }
        return '';
    },
    getArrayByNode: function (result, node) {
        const data = result ? Vue.prototype.$lodash.get(result.RAIZ, node) : null;

        return Vue.prototype.$_aura.getArray(() => data);
    },
    getObjectByNode: function (result, node) {
        const data = result ? Vue.prototype.$lodash.get(result.RAIZ, node) : null;

        return Vue.prototype.$_aura.getObject(data);
    },
    getParamsByFields: function (fields) {
        const params = {};
        fields.forEach(field => {
            let val = field.value;
            if ((field.component === 'ADatePicker' || field.component === 'ATimePicker') && field.props['text-field'] && field.value) {
                val = field.value;
            }
            params[field.name] = field.parser ? field.parser(val) : val;
        });
        return params;
    },

    booleanToNumber: function (value) {
        if (value != null) {
            return value ? 1 : 0;
        }
        return undefined;
    },

    dateToString: function (value) {
        return value ? value.server : undefined;
    },

    relativeDateParser: function (value) {
        // this function expects the returned object from 'ADatePicker' configured as a relative date picker
        const { $moment } = Vue.prototype;

        if (value == null) {
            return null;
        }

        if (value.type === 'DATE') {
            return value.date;
        }

        if (value.type !== 'RELATIVE') {
            if ($moment(value, 'DD/MM/YYYY', true).isValid()) {
                return value;
            }
            return null;
        }

        let chosenDate = null;

        switch (value.value) {
            case 'TODAY':
                chosenDate = date.getToday();
                break;
            case 'YESTERDAY':
                chosenDate = date.getYesterday();
                break;
            case 'FIRST_CURRENT_MONTH':
                chosenDate = date.getFirstDayOfCurrentMonth();
                break;
            case 'LAST_CURRENT_MONTH':
                chosenDate = date.getLastDayOfCurrentMonth();
                break;
            case 'FIRST_PREVIOUS_MONTH':
                chosenDate = date.getFirstDayOfPreviousMonth();
                break;
            case 'LAST_PREVIOUS_MONTH':
                chosenDate = date.getLastDayOfPreviousMonth();
                break;
            case 'FIRST_CURRENT_TRIMESTER':
                chosenDate = date.getFirstDayOfCurrentTrimester();
                break;
            case 'LAST_CURRENT_TRIMESTER':
                chosenDate = date.getLastDayOfCurrentTrimester();
                break;
            case 'FIRST_PREVIOUS_TRIMESTER':
                chosenDate = date.getFirstDayOfPreviousTrimester();
                break;
            case 'LAST_PREVIOUS_TRIMESTER':
                chosenDate = date.getLastDayOfPreviousTrimester();
                break;
            case 'LAST_X_DAYS':
                if (value.lastXDays != null) {
                    chosenDate = date.getLastXDays(value.lastXDays);
                    break;
                }

                chosenDate = date.getLastXDays(0);
                break;
            default:
                return null;
        }

        if (value.format == null) {
            return $moment(chosenDate).format('DD/MM/YYYY');
        }

        if (value.format === 'date') {
            return chosenDate;
        }

        if (value.format === 'moment') {
            return $moment(chosenDate);
        }

        return $moment(chosenDate).format(value.format);
    },
    dateTimeToString: function (value) {
        if (value) {
            return value.split(' ');
        }
        return undefined;
    },

    arrayToString: function (value) {
        return value != null && value.length !== 0 ? JSON.stringify(value) : undefined;
    },
    intArrayToString: function (value) {
        if (value != null && value.length !== 0) {
            const intValue = value.map(element => {
                if (typeof element === 'number') {
                    return parseInt(element, 10);
                }
                return element;
            });
            return JSON.stringify(intValue);
        }
        return undefined;
    },
    attributesToString: function (value) {
        let json = '';
        if (value) {
            value.forEach(attribute => {
                if (attribute != null && attribute.attribute && attribute.attribute.ID_ATRIBUTO != null) {
                    if (json !== '') {
                        json = `${json}|`;
                    }
                    json = `${json}${attribute.attribute.ID_ATRIBUTO};`;
                    if (attribute.comparator != null && attribute.comparator.VALUE != null) {
                        json = `${json}${attribute.comparator.VALUE};`;
                    }
                    if (attribute.attributeValue != null) {
                        if (Array.isArray(attribute.attributeValue)) {
                            const values = attribute.attributeValue.map(obj => obj.ID_ATRIBUTO_VALOR);
                            json = `${json}${values.join(',')}`;
                        } else if (attribute.attributeValue.ID_ATRIBUTO_VALOR != null) {
                            json = `${json} ${attribute.attributeValue.ID_ATRIBUTO_VALOR}`;
                        } else if (attribute.attributeValue.VALOR_ATRIBUTO != null) {
                            json = `${json}${attribute.attributeValue.VALOR_ATRIBUTO}`;
                        }
                    }
                }
            });
        }
        return json;
    },
    compareDateTimeString: function (a, b) {
        const momentA = Vue.prototype.$moment(a, 'DD/MM/YYYY HH:mm:ss');
        const momentB = Vue.prototype.$moment(b, 'DD/MM/YYYY HH:mm:ss');
        return momentA.diff(momentB);
    },

    nullSafeJsonStringify: function (value) {
        if (value === null) {
            return null;
        }
        return JSON.stringify(value);
    },

    durationPeriodToArrayMinMax: function (value, format) {
        if (value) {
            const valueArray = [];
            valueArray.push(value.from && Vue.prototype.$moment.isDuration(value.from) && value.from.isValid() ? value.from.as(format) : null);
            valueArray.push(value.to && Vue.prototype.$moment.isDuration(value.to) && value.to.isValid() ? value.to.as(format) : null);
            return valueArray;
        }
        return null;
    },

    durationPeriodToArrayMinMaxMilliseconds: (value) => Vue.prototype.$util.durationPeriodToArrayMinMax(value, 'ms'),

    durationPeriodToArrayMinMaxSeconds: (value) => Vue.prototype.$util.durationPeriodToArrayMinMax(value, 's'),

    filterString: function (value, search) {
        const valueDebuur = Vue.prototype.$lodash.deburr(value);
        const searchDebuur = Vue.prototype.$lodash.deburr(search);
        return valueDebuur.includes(searchDebuur);
    },

    checkExistDeep: function (provider, childrenIdentity, identity, value) {
        if (provider && Array.isArray(provider) && identity && childrenIdentity) {
            return provider.some(item => {
                if (item[identity] === value) {
                    return true;
                }
                return Vue.prototype.$util.checkExistDeep(item[childrenIdentity], childrenIdentity, identity, value);
            });
        }
        return false;
    },

    unmask: function (maskedValue, mask) {
        const defaultTokens = ['#', 'X', 'S', 'A', 'a', '!'];
        let unmaskedValue = '';
        let isToken;
        if (maskedValue) {
            for (let i = 0; i < maskedValue.length; i++) {
                isToken = false;
                for (let j = 0; j < defaultTokens.length; j++) {
                    if (mask[i] === defaultTokens[j]) {
                        isToken = true;
                    }
                }
                if (isToken) {
                    unmaskedValue += maskedValue[i];
                }
            }
            return unmaskedValue;
        }
        return null;
    },

    removeNonDigit: function (value) {
        let nonDigitValue;
        if (value != null) {
            nonDigitValue = value.replace(/\D/g, '');
            return nonDigitValue;
        }
        return value;
    },

    validateEmail: function (email) {
        if (!email || !email.trim()) {
            return true;
        }
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email.trim());
    },

    validateMultipleEmails: function (emailString) {
        if (!emailString || !emailString.trim()) {
            return true;
        }
        const emailArray = emailString.split(';');
        let isValid = true;
        emailArray.map((email) => {
            if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email.trim())) {
                isValid = false;
            }
            return 0;
        });
        return isValid;
    },

    validatePhoneFormat: function (phone) {
        if (phone == null) {
            return false;
        }

        return /^\(\d{2}\)\s\d{4,5}-\d{4}$/.test(phone.trim());
    },

    validateCepFormat: function (cep) {
        if (cep == null) {
            return false;
        }

        return /^\d{5}-\d{3}$/.test(cep.trim());
    },

    maskCep: function (unmaskCep) {
        if (unmaskCep != null) {
            return unmaskCep.replace(/(\d{5})(\d{3})/, '$1-$2');
        }
        return unmaskCep;
    },

    maskPhone: function (rawNumber, countryCode) {
        if (rawNumber && rawNumber !== '' && rawNumber !== 0 && rawNumber !== '0') {
            if (countryCode && phoneUtil) {
                try {
                    const number = phoneUtil.parse(rawNumber, countryCode);
                    return phoneUtil.formatInOriginalFormat(number, countryCode);
                } catch (e) {
                    return rawNumber;
                }
            }
            return rawNumber.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
        }
        return rawNumber;
    },

    validateIdentityFormat: function (identityNum, countryCode, identityType) {
        if (identityNum && countryCode && identityType && identityType.COD_DOCUMENTO) {
            const identityNumber = identityNum.replace(/[^0-9A-Za-z]/g, '');
            if (identityNumber && libStdnum.stdnum[countryCode][identityType.COD_DOCUMENTO.toLowerCase()]) {
                return libStdnum.stdnum[countryCode][identityType.COD_DOCUMENTO.toLowerCase()].validate(identityNumber).isValid;
            }
            return identityNumber.length === identityType.MASCARA.replace(/[^AX#]/g, '').length;
        }
        return true;
    },

    maskIdentityNumber: function (unmaskIdentityNumber, countryCode, identityType) {
        if (unmaskIdentityNumber && countryCode && identityType) {
            if (libStdnum.stdnum[countryCode][identityType.toLowerCase()] !== undefined && libStdnum.stdnum[countryCode][identityType.toLowerCase()].validate(unmaskIdentityNumber).isValid) {
                return libStdnum.stdnum[countryCode][identityType.toLowerCase()].format(unmaskIdentityNumber);
            }
        }
        return unmaskIdentityNumber;
    },

    stringToAttributesBase: function (value) {
        if (value) {
            const attributesString = value.split('|');
            let index = 0;
            return attributesString.map(attributeString => {
                const [attribute, comparator, attributeValue] = attributeString.split(';', 3);
                const attributeObj = {};
                attributeObj.attribute = attribute;
                attributeObj.comparator = comparator;
                attributeObj.attributeValue = attributeValue;
                attributeObj.index = index++;
                return attributeObj;
            });
        }
        return null;
    },

    // valida se 2 arrays são iguais sem se importar com a ordem
    deepEquals: function (a, b) {
        return a.length === b.length && a.every(k => b.includes(k));
    },

    loadCommandFilter: function (command, values) {
        if (!command.fields && command.params) {
            command.fields = command.params;
        }

        Object.keys(command.fields)
            .forEach(key => {
                const field = command.fields[key];

                const selectedValue = values.find(f => {
                    if (f.key === field.name) {
                        return true;
                    }
                    // deep search dentro da key, se, ao menos todos os valores do comando
                    // estiverem presentes no valor então sera valido
                    if (Array.isArray(f.key) && Array.isArray(field.name)) {
                        return this.deepEquals(f.key, field.name);
                    }
                    return false;
                });
                if (selectedValue && selectedValue.value != null) {
                    field.value = selectedValue.value;
                } else if (selectedValue && selectedValue.value === null) {
                    field.value = null;
                }
            });
    },

    getCommandPageField: function (command) {
        return Object.values(command.fields).find(value => value.name === 'PAGE');
    },

    getFiltersByCommand: function (command, parser, exclude = []) {
        const filters = [];
        Object.keys(command.fields)
            .forEach(key => {
                const item = command.fields[key];
                const name = !Array.isArray(item.name) ? [item.name] : item.name;

                if (!exclude.some(keyExclude => name.includes(keyExclude))) {
                    const value = typeof item.value === 'number' ? item.value : (item.value || null);

                    filters.push({
                        key: item.name,
                        value: item.parser && parser ? item.parser(value) : value,
                    });
                }
            });
        return filters;
    },

    downloadUrlFile: async function (url) {
        const config = {
            method: 'GET',
            url: this.formatUrlDownloadFile(url),
            responseType: 'blob',
            headers: {
                Authorization: Vue.prototype.$_aura.getToken(),
            },
        };

        let req = null;

        try {
            this.showLoading();
            req = await axios(config);
        } finally {
            this.hideLoading();
        }

        return URL.createObjectURL(new Blob([req.data], { type: req.headers['content-type'] }));
    },

    formatUrlDownloadFile: function (url) {
        if (url.indexOf('auth_token') >= 0) {
            url = url.replace(Vue.prototype.$_aura.getHTTPUrl(), '');
            const urlParams = new URLSearchParams(url);
            urlParams.delete('auth_token');

            return `${Vue.prototype.$_aura.getHTTPUrl()}?${urlParams.toString()}`;
        }

        return url;
    },

    filtersToString: function (filters, removeNull = true, includes, excludes = []) {
        if (Array.isArray(filters)) {
            if (removeNull) {
                filters = filters.filter(param => param.value || param.value === 0);
            }
            return filters.map(filter => {
                let keys = filter.key;
                let values;
                if (filter.value.type === 'RELATIVE' || filter.value.type === 'DATE') {
                    values = Vue.prototype.$util.relativeDateParser(filter.value);
                } else {
                    values = filter.value;
                }
                if (!Array.isArray(keys)) {
                    keys = [keys];
                }
                if (!Array.isArray(values)) {
                    values = [values];
                }
                let i = 0;
                const items = [];
                keys.forEach(key => {
                    if ((includes && includes.some(keyInclude => keyInclude === key)) || (!excludes.some(keyExclude => keyExclude === key))) {
                        const value = (Array.isArray(values) && keys.length === 1) ? values.join(',') : values[i++];
                        items.push(`CMD_${key}=${value != null ? value : ''}`);
                    }
                });
                return items.join('&');
            }).join('&');
        }

        let output = '';
        Object.keys(filters).forEach(key => {
            const value = filters[key];
            if ((!includes || includes.some(keyInclude => keyInclude === key)) && (!excludes.some(keyExclude => keyExclude === key))) {
                if (removeNull && !(value || value === 0)) {
                    return;
                }

                if (output.length > 0) {
                    output += '&';
                }
                output += `CMD_${key}=${value != null ? value : ''}`;
            }
        });
        return output;
    },
    downloadFile: function (resolve, fileName) {
        if (resolve) {
            const link = document.createElement('a');

            link.href = resolve;
            link.setAttribute('download', fileName);

            document.body.appendChild(link);
            link.click();

            Vue.prototype.$_aura.showSuccessMessage(i18n.t('SUCCESSFULLY_GENERATED_FILE'));
        } else {
            Vue.prototype.$_aura.showErrorMessage(i18n.t('UNABLE_TO_GENERATE_FILE'));
        }
    },

    uuidv4: uuidv4,

    objToXML: function (obj, name) {
        let xml = '';
        if (name) {
            xml += `<${name}`;
            if (obj['@attributes']) {
                Object.entries(obj['@attributes']).forEach(([key, value]) => {
                    const escapedKey = this.encodeStringToXML(key);
                    const escapedValue = this.encodeStringToXML(value);
                    xml += ` ${escapedKey}="${escapedValue}"`;
                });
            }
            xml += '>';
            if (obj['#text']) {
                xml += obj['#text'];
            }
        }
        if (typeof obj === 'object') {
            Object.entries(obj).forEach(([key, value]) => {
                if (key !== '@attributes' && key !== '#text') {
                    if (Array.isArray(value)) {
                        value.forEach(child => {
                            xml += this.objToXML(child, key);
                        });
                    } else {
                        xml += this.objToXML(value, key);
                    }
                }
            });
        } else {
            xml += obj;
        }
        if (name) {
            xml += `</${name}>`;
        }
        return xml;
    },

    encodeStringToXML: function (value) {
        if (value !== null && value !== undefined) {
            return value.toString()
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&apos;');
        }
        return '';
    },

    decodeXMLtoString: function (value) {
        return value.replace(/&apos;/g, "'")
            .replace(/&quot;/g, '"')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<')
            .replace(/&amp;/g, '&');
    },

    openOnGoogleMaps: function (lat, lng) {
        const url = `https://www.google.com/maps?q=${lat},${lng}&z=17`;
        window.open(url, '_blank');
    },

    getFileIcon: function (type) {
        const imgPattern = new RegExp('(png|gif|jpg|jpeg|jfif|psd|exif|raw|webp|tiff|svg|bmp|image)', 'i');
        const docPattern = new RegExp('(odt|txt|doc|docx|text)', 'i');
        const pdfPattern = new RegExp('(pdf)', 'i');
        const pptPattern = new RegExp('(ppt|pptx)', 'i');
        const xlsPattern = new RegExp('(xls|xlsx)', 'i');
        const vidPattern = new RegExp('(avi|mpeg|mp4|mkv|mov|wmv|flv|mv|3gp|3g2|video)', 'i');
        const audPattern = new RegExp('(wav|mp3|aac|ogg|wma|alac|flac|aiff|pcm|audio)', 'i');
        const zipPattern = new RegExp('(zip|rar)', 'i');

        if (imgPattern.test(type)) {
            return 'far fa-file-image';
        } if (docPattern.test(type)) {
            return 'far fa-file-word';
        } if (pdfPattern.test(type)) {
            return 'far fa-file-pdf';
        } if (pptPattern.test(type)) {
            return 'far fa-file-powerpoint';
        } if (xlsPattern.test(type)) {
            return 'far fa-file-excel';
        } if (vidPattern.test(type)) {
            return 'far fa-file-video';
        } if (audPattern.test(type)) {
            return 'far fa-file-audio';
        } if (zipPattern.test(type)) {
            return 'far fa-file-archive';
        }
        return 'fas fa-file';
    },

    stringToLocaleMonetary: function (value) {
        return Number(Number(value).toFixed(2)).toLocaleString();
    },

    parserLayersToString: function (layers) {
        if (layers) {
            const layersString = layers.map(layer => {
                const pointsItems = [];
                if (layer instanceof L.Marker) {
                    pointsItems.push(layer.getLatLng());
                } else if (layer instanceof L.Polygon) {
                    pointsItems.push(...layer.getLatLngs()[0]);
                    const comparison = Object.entries(layer.getLatLngs()[0]);
                    const { lat: initialLatitude, lng: initialLongitude } = comparison[0][1];
                    const { lat: finalLatitude, lng: finalLongitude } = comparison[comparison.length - 1][1];
                    if (initialLatitude !== finalLatitude && initialLongitude !== finalLongitude) {
                        pointsItems.push(layer.getLatLngs()[0][0]);
                    }
                } else {
                    pointsItems.push(...layer.getLatLngs());
                }
                const points = pointsItems.map(point => `${point.lng},${point.lat}`);
                return points.join(';');
            })
                .join('|');
            return layersString;
        }
        return null;
    },

    attributesToAttributesGroup: function (attributes) {
        const isImage = (atr) => atr.TIPO === 'ASM' || atr.TIPO === 'FTG' || atr.TIPO === 'FTS';
        const provider = [];
        const groups = Vue.prototype.$lodash.remove(attributes, attribute => attribute.TIPO === 'COM');
        const attributeGroup = Vue.prototype.$lodash.groupBy(attributes, attribute => attribute.ID_ATRIBUTO_PAI || 'none');
        attributeGroup.none.forEach(value => {
            if (isImage(value)) {
                value.type = 'info';
            }
        });
        const groupDefault = {
            NOME: i18n.t('DEFAULT'),
            PADRAO: 1,
            ORDER: 1,
            attributes: attributeGroup.none,
            ID_ATRIBUTO: -1,
        };
        provider.push(groupDefault);
        groups.forEach(group => {
            if (attributeGroup[group.ID_ATRIBUTO]) {
                attributeGroup[group.ID_ATRIBUTO].forEach(value => {
                    if (isImage(value)) {
                        value.type = 'info';
                    }
                });
            }
            const attributesGroup = attributeGroup[group.ID_ATRIBUTO] || [];
            provider.push({
                ID_ATRIBUTO: group.ID_ATRIBUTO,
                NOME: group.NOME,
                ORDER: provider.length + 1,
                PADRAO: 0,
                attributes: attributesGroup,
            });
        });
        return provider;
    },

    getRandomColor: function () {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },

    /**
     * Method that generates visually different colors using an incremental index
     *
     * Example:
     * items.forEach((item, index) => {
     *      item.color = this.$util.getRandomColorSeeded(index);
     * })
     *
     * Each generated color will be different enough from the others because of
     * the usage of the golden angle along with the color wheel concept
     *
     * More info in https://medium.com/@winwardo/simple-non-repeating-colour-generation-6efc995832b8
     *
     * @param index : Number | String
     */
    getNonRepeatedRandomColor: function (index) {
        // generate hue using the golden angle
        let h = (Number(index) + 1) * 137.508;
        // format hue to be in interval [0, 1]
        h %= 360;
        h /= 360;

        // randomly generate saturation and lightness
        const s = Math.random() * (1.0 - 0.6) + 0.6;
        const l = Math.random() * (0.7 - 0.4) + 0.4;

        // format hsl to rgb
        return this.hslToHex(h, s, l);
    },

    hslToHex: function (h, s, l) {
        const hue2rgb = (p, q, t) => {
            if (t < 0) {
                t += 1;
            }
            if (t > 1) {
                t -= 1;
            }
            if (t < 1 / 6) {
                return p + (q - p) * 6 * t;
            }
            if (t < 1 / 2) {
                return q;
            }
            if (t < 2 / 3) {
                return p + (q - p) * (2 / 3 - t) * 6;
            }
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;

        const r = hue2rgb(p, q, h + 1 / 3) * 255;
        const g = hue2rgb(p, q, h) * 255;
        const b = hue2rgb(p, q, h - 1 / 3) * 255;

        return this.rgbToHex(r, g, b);
    },

    rgbToHex: function (r, g, b) {
        r = parseInt(r, 10).toString(16);
        g = parseInt(g, 10).toString(16);
        b = parseInt(b, 10).toString(16);

        if (r.length === 1) {
            r = `0${r}`;
        }
        if (g.length === 1) {
            g = `0${g}`;
        }
        if (b.length === 1) {
            b = `0${b}`;
        }

        return `#${r}${g}${b}`;
    },

    colorToHexaNumber: function (color) {
        return color.toString(16).replace('#', '0x');
    },

    colorLuminance: function (hex, lum) {
        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        lum = lum || 0;

        // convert to decimal and change luminosity
        let rgb = '#';
        let c;
        let i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += (`00${c}`).substr(c.length);
        }

        return rgb;
    },

    formatStructureDescription: function (item) {
        let label = item.ID_ESTRUTURA;

        const unformattedStructureNum = item.NUM_ESTRUTURA;

        let structureNum = null;
        if (unformattedStructureNum) {
            structureNum = (unformattedStructureNum < 100 ? '0' : '') + (unformattedStructureNum < 10 ? '0' : '') + unformattedStructureNum;
        }

        label = `( ${item.TIPO_ESTRUTURA || item.TIPO_ESTRUTURA_OBRA} )  ${(structureNum || '')}`;

        if (item.DESC_ESTRUTURA) {
            label += (structureNum ? ' - ' : '') + item.DESC_ESTRUTURA;
        }

        if (item.TIPO_ESTRUTURA === 'T' && item.NUM_ESTRUTURA_INICIAL && item.NUM_ESTRUTURA_FINAL) {
            const initialStructureNum = (item.NUM_ESTRUTURA_INICIAL < 100 ? '0' : '') + (item.NUM_ESTRUTURA_INICIAL < 10 ? '0' : '') + item.NUM_ESTRUTURA_INICIAL;
            const finalStructureNum = (item.NUM_ESTRUTURA_FINAL < 100 ? '0' : '') + (item.NUM_ESTRUTURA_FINAL < 10 ? '0' : '') + item.NUM_ESTRUTURA_FINAL;

            label += ` (${initialStructureNum} - ${finalStructureNum})`;
        }

        if (item.ID_ESTRUTURA == null || item.ID_ESTRUTURA.length === 0) {
            return '';
        }
        return label;
    },
    numberToBoolean: function (value) {
        if (!Number.isNaN(value)) {
            value = Number(value);
        }
        if (value === 1) {
            return i18n.t('YES');
        } if (value === 0) {
            return i18n.t('NO');
        }
        return i18n.t('ALL');
    },
    isJsonString: function (text) {
        if (typeof text !== 'string') {
            return false;
        }
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    },
    dateSort: (a, b) => {
        const momentA = Vue.prototype.$moment(a, 'DD/MM/YYYY');
        const momentB = Vue.prototype.$moment(b, 'DD/MM/YYYY');

        if (!momentA.isValid() && !momentB.isValid()) {
            return 0;
        }
        if (!momentA.isValid()) {
            return -1;
        }
        if (!momentB.isValid()) {
            return 1;
        }

        if (momentA.isAfter(momentB)) {
            return 1;
        }
        if (momentA.isBefore(momentB)) {
            return -1;
        }
        return 0;
    },

    dateTimeSort: (a, b) => {
        const momentA = Vue.prototype.$moment(a, 'DD/MM/YYYY HH:mm:ss');
        const momentB = Vue.prototype.$moment(b, 'DD/MM/YYYY HH:mm:ss');

        if (!momentA.isValid() && !momentB.isValid()) {
            return 0;
        }
        if (!momentA.isValid()) {
            return -1;
        }
        if (!momentB.isValid()) {
            return 1;
        }

        if (momentA.isAfter(momentB)) {
            return 1;
        }
        if (momentA.isBefore(momentB)) {
            return -1;
        }
        return 0;
    },
    /**
     * Retrieves the decimal or group separator of a given locale.
     *
     * @param {string} locale - The locale code (e.g., 'en-US', 'fr-FR'). If undefined, uses the current locale from store
     * @param {string} separatorType - The type of separator to retrieve ('decimal' or 'group'). If undefined, defaults to 'group'
     * @returns {string} The decimal or group separator for the specified locale.
     */
    getMonetarySeparator: function (locale = undefined, separatorType = 'group') {
        locale = locale === undefined ? store.getters.getCurrency.locale : locale;
        const numberWithSeparators = 1000.1;
        return Intl.NumberFormat(locale)
            .formatToParts(numberWithSeparators)
            .find(part => part.type === separatorType)
            .value;
    },

    /** Takes an string as a param and return it as a Float */
    formatToNumber: function (value) {
        if (value instanceof String || typeof value === 'string') {
            let foundNonDigit = false;
            let result = '';

            for (let i = value.length - 1; i >= 0; i--) {
                const char = value[i];
                const isDigit = /\d/.test(char) || (i === 0 && char === '-');
                if (isDigit || (!foundNonDigit && !isDigit)) {
                    result = char + result;
                    if (!isDigit) {
                        foundNonDigit = true;
                    }
                }
            }

            return parseFloat(result.replace(',', '.'));
        }
        return value;
    },

    inputRules: {
        required: value => !!value || i18n.t('MANDATORY'),
    },

    asDate: function (value, defaultDateFormat = false) {
        if (!value) {
            return null;
        }
        if (value instanceof Date) {
            return value;
        } if (value.constructor === String) {
            if (defaultDateFormat) {
                const valueLength = value.length;
                const day = value.substring(0, 2);
                const month = value.substring(3, 5);
                const year = value.substring(6, 10);
                const time = value.substring(11, valueLength);
                return !time ? moment(`${year}-${month}-${day}`) : moment(`${year}-${month}-${day}T${time}`);
            }
            return moment(value);
        }
        return null;
    },

    isOne: function (item) {
        return !!item && parseInt(item, 10) === 1;
    },

    isStringEmpty: function (item) {
        return item === null || item === undefined || item.toString().trim().length === 0;
    },

    notNull: function (item) {
        return item !== null && item !== undefined;
    },

    /** accepts all values and format to monetary type if is possible and return it, else return "" */
    asMonetary: function (value, precision = null, hideGroupingSymbol = false) {
        const { code, symbol, locale } = store.getters.getCurrency;
        const escapedSymbol = symbol.replace('$', '\\$');
        const cleanUpRegex = new RegExp(`${code}|${escapedSymbol}|\\s+`, 'g');

        if (typeof value === 'string') {
            value = value.replace(cleanUpRegex, '');
            const regex = new RegExp('[^\\d.,-]', 'g');
            if (regex.test(value)) {
                return '';
            }
            value = this.formatToNumber(value);
        }

        value = Number.parseFloat(value);

        if (!Number.isNaN(value)) {
            if (precision != null) {
                return value.toLocaleString(locale, {
                    style: 'currency',
                    currency: code,
                    currencyDisplay: 'code',
                    minimumFractionDigits: precision,
                    maximumFractionDigits: precision,
                    useGrouping: !hideGroupingSymbol,
                }).replace(cleanUpRegex, '');
            }

            const options = {
                style: 'currency',
                currency: code,
                currencyDisplay: 'code',
                useGrouping: !hideGroupingSymbol,
            };

            if (this.getLocaleMinimumFractionDigits(locale, code) === 0) {
                options.minimumFractionDigits = 2;
                options.maximumFractionDigits = 2;
            }

            return value.toLocaleString(locale, options).replace(cleanUpRegex, '');
        }

        return '';
    },
    getLocaleMinimumFractionDigits: function (locale, currencyCode) {
        return Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyCode,
        }).resolvedOptions().minimumFractionDigits;
    },
    getIdentityNumberMask: function (identityObject, identityNumber) {
        const masks = this.getIdentityNumberMasks(identityObject);

        if (!identityNumber || masks.length === 1) {
          return masks[0];
        }

        let maskIndex = 0;

        // When the input has the same length as the mask, it has used the extra space, so it's time to change
        if (identityNumber.replace(/[^0-9A-Za-z]/g, '').length === masks[maskIndex].replaceAll(/[.,-]/g, '').length) {
          maskIndex++;
        }

        return masks[maskIndex];
    },
    getIdentityNumberMasks: function (identityObject) {
        const masks = identityObject?.MASCARA?.split('||');

        if (masks !== undefined) {
          masks.sort((a, b) => a.length - b.length);

          // Adds an extra space to the smaller masks
          for (let i = 0; i < masks.length - 1; i++) {
            masks[i] += 'X';
          }

          return masks;
        }

        return ['###.###.###-##'];
      },
    legalIdentityNumberMask: function () {
        switch (Vue.prototype.$_aura.getUser().countryCode) {
            case 'BR': return '##.###.###/####-##';
            case 'CL': return '##.###.###-X';
            default: return '';
        }
    },

    removeExpNumber: function (value) {
        const regex = /\d/i;
        const verifyValueStr = () => {
            const str = String(value);
            const arr = [...str];
            if (arr.length > 0) {
                return !(arr.some(char => char.toLowerCase() === 'e'));
            }
            return true;
        };

        return String(value).match(regex) && verifyValueStr(value) && Number.isInteger(Number(value)) && Number(value) >= 0;
    },
    getYesterday: function () {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayFormatted = $moment(yesterday).format('DD/MM/YYYY');

        return yesterdayFormatted;
    },
    getFirstDayOfCurrentMonth: function () {
        const today = new Date();
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const firstDayOfCurrentMonthFormatted = $moment(firstDayOfCurrentMonth).format('DD/MM/YYYY');

        return firstDayOfCurrentMonthFormatted;
    },
    getLastDayOfCurrentMonth: function () {
        const today = new Date();
        const lastDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const lastDayOfCurrentMonthFormatted = $moment(lastDayOfCurrentMonth).format('DD/MM/YYYY');

        return lastDayOfCurrentMonthFormatted;
    },
    getFirstDayOfPreviousMonth: function () {
        const today = new Date();
        const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const firstDayOfPreviousMonthFormatted = $moment(firstDayOfPreviousMonth).format('DD/MM/YYYY');

        return firstDayOfPreviousMonthFormatted;
    },
    getLastDayOfPreviousMonth: function () {
        const today = new Date();
        const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        const lastDayOfPreviousMonthFormatted = $moment(lastDayOfPreviousMonth).format('DD/MM/YYYY');

        return lastDayOfPreviousMonthFormatted;
    },
    getFirstDayOfCurrentTrimester: function () {
        const today = new Date();
        const currentMonth = today.getMonth() + 1; // Add 1 because getMonth() returns 0-indexed values
        let firstDay;

        // Determine the first date of the current trimester
        if (currentMonth <= 3) {
            firstDay = new Date(today.getFullYear(), 0, 1); // January 1st of the current year
        } else if (currentMonth <= 6) {
            firstDay = new Date(today.getFullYear(), 3, 1); // April 1st of the current year
        } else if (currentMonth <= 9) {
            firstDay = new Date(today.getFullYear(), 6, 1); // July 1st of the current year
        } else {
            firstDay = new Date(today.getFullYear(), 9, 1); // October 1st of the current year
        }

        const firstDayFormatted = $moment(firstDay).format('DD/MM/YYYY');

        return firstDayFormatted;
    },
    getLastDayOfCurrentTrimester: function () {
        const today = new Date();
        const currentMonth = today.getMonth() + 1; // Add 1 because getMonth() returns 0-indexed values
        let lastDay;

        // Determine the last date of the current trimester
        if (currentMonth <= 3) {
            lastDay = new Date(today.getFullYear(), 2, 31); // March 31th of the current year
        } else if (currentMonth <= 6) {
            lastDay = new Date(today.getFullYear(), 5, 30); // June 30st of the current year
        } else if (currentMonth <= 9) {
            lastDay = new Date(today.getFullYear(), 8, 30); // September 30st of the current year
        } else {
            lastDay = new Date(today.getFullYear(), 11, 31);// December 31st of the current year
        }

        const lastDayFormatted = $moment(lastDay).format('DD/MM/YYYY');

        return lastDayFormatted;
    },
    getFirstDayOfLastTrimester: function () {
        const today = new Date();

        const currentMonth = today.getMonth() + 1; // Add 1 because getMonth() returns 0-indexed values
        const currentYear = today.getFullYear();
        let firstDay;

        // Determine the first date of the previous trimester
        if (currentMonth <= 3) {
            firstDay = new Date(currentYear - 1, 9, 1); // October 1st of the previous year
        } else if (currentMonth <= 6) {
            firstDay = new Date(currentYear, 0, 1);// January 1st of the current year
        } else if (currentMonth <= 9) {
            firstDay = new Date(currentYear, 3, 1);// April 1st of the current year
        } else {
            firstDay = new Date(currentYear, 6, 1);// July 1st of the current year
        }

        const firstDayFormatted = $moment(firstDay).format('DD/MM/YYYY');

        return firstDayFormatted;
    },
    getLastDayOfLastTrimester: function () {
        const today = new Date();

        const currentMonth = today.getMonth() + 1; // Add 1 because getMonth() returns 0-indexed values
        const currentYear = today.getFullYear();
        let lastDay;

        // Determine the first and last date of the previous trimester
        if (currentMonth <= 3) {
            lastDay = new Date(currentYear - 1, 12, 0); // December 31st of previous year
        } else if (currentMonth <= 6) {
            lastDay = new Date(currentYear, 3, 0); // March 31th of the current year
        } else if (currentMonth <= 9) {
            lastDay = new Date(currentYear, 6, 0); // June 30st of the current year
        } else {
            lastDay = new Date(currentYear, 9, 0); // September 30st of the current year
        }

        const lastDayFormatted = $moment(lastDay).format('DD/MM/YYYY');

        return lastDayFormatted;
    },
    getLastXDays: function () {
        const firstDay = new Date();
        const lastXDays = this.lastXDays ?? 0;

        firstDay.setDate(firstDay.getDate() - lastXDays);

        return $moment(firstDay).format('DD/MM/YYYY');
    },
    getDateFormatOfUserLocale: function () {
        return moment().creationData().locale.longDateFormat('L');
    },
    getTimeFormatOfUserLocale: function (timeWithSeconds = false) {
        if (timeWithSeconds) {
            return moment().creationData().locale.longDateFormat('LTS');
        }
        return moment().creationData().locale.longDateFormat('LT');
    },
    getDateTimeFormatOfUserLocale: function (timeWithSeconds = false) {
        if (timeWithSeconds) {
            return moment().creationData().locale.longDateFormat('L HH:mm:ss');
        }
        return moment().creationData().locale.longDateFormat('L HH:mm');
    },
    getQuarterDateFormatOfUserLocale: function () {
        const dateString = moment().creationData().locale.longDateFormat('L');
        return dateString.replace(/M{1,2}/, 'QQ').replace(/(\WD{1,2}|D{1,2}\W)/, '');
    },
    formatDateWithUserLocale: function (serverDate) {
        if (!serverDate) {
            return serverDate;
        }

        // assumes server standard 'DD/MM/YYYY'
        const m = moment(serverDate, 'DD/MM/YYYY', true);
        if (m.isValid()) {
            return m.format('L');
        }

        return serverDate;
    },
    formatTimeWithUserLocale: function (serverTime) {
        if (!serverTime) {
            return serverTime;
        }

        // assumes server standard 'HH:mm' or 'HH:mm:ss'
        let m = moment(serverTime, 'HH:mm', true);
        if (m.isValid()) {
            return m.format('LT');
        }

        m = moment(serverTime, 'HH:mm:ss', true);
        if (m.isValid()) {
            return m.format('LTS');
        }

        return serverTime;
    },
    formatDateTimeWithUserLocale: function (serverDateTime) {
        if (!serverDateTime) {
            return serverDateTime;
        }

        let m = moment(serverDateTime, ['DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY - HH:mm:ss'], true);
        if (m.isValid()) {
            return m.format('L HH:mm:ss');
        }

        m = moment(serverDateTime, ['DD/MM/YYYY HH:mm', 'DD/MM/YYYY - HH:mm'], true);
        if (m.isValid()) {
            return m.format('L HH:mm');
        }

        m = moment(serverDateTime, 'DD/MM/YYYY', true);
        if (m.isValid()) {
            return m.format('L');
        }

        return serverDateTime;
    },

    getProcessDescription: function (process) {
        if (process.ENTIDADE_VALOR_PROTOCOLO) {
            return `${i18n.t('PROTOCOL')} ${process.ENTIDADE_VALOR_PROTOCOLO}`;
        }

        if (process.ENTIDADE_VALOR) {
            return `${i18n.t('TICKET')} ${process.ENTIDADE_VALOR}`;
        }

        return `${process.DESCRICAO || ''}`;
    },
    getTitleText: function (title, reportTitle) {
        if (!title) {
            return reportTitle ? `${reportTitle}` : `${i18n.t('REPORT')}`;
        }
        return reportTitle ? `${reportTitle} - ${title}` : `${i18n.t('REPORT')} - ${title}`;
    },
    getImageDimensions: async function (dataImageUri) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = dataImageUri;

            img.onload = () => resolve([img.width, img.height]);
            img.onerror = (e) => reject(e);
        });
    },
    svgsToPdf: async function ({
        svgsElements,
        htmlElements,
        indicatorElements,
        tableElements,
        reportTitle = '',
        logo,
        marginX = 50,
        marginY = 50,
        marginTitle = 25,
        fontSize = 16,
        lineSpacing = 10,
        textSpacing = 20,
        fileName = 'document.pdf',
        mapsElements,
    }) {
        const svgs = [];
        const htmls = [];
        const indicators = [];
        const tables = [];
        const mapas = [];

        if (svgsElements) {
            svgsElements.forEach(({ svgElement, title }) => {
                const width = Number(svgElement.getAttribute('width'));
                const height = Number(svgElement.getAttribute('height'));

                const textsElements = svgElement.getElementsByTagName('text');
                for (let i = 0; i < textsElements.length; i++) {
                    textsElements[i].setAttribute('stroke', 'none');
                    textsElements[i].setAttribute('dy', '0.2em');
                }

                const pathsToRemove = svgElement.querySelectorAll('path[fill="#aaa"], path[fill="#2f4554"]');
                const textsToRemove = svgElement.querySelectorAll('text[style="font: 12px sans-serif;"]');

                pathsToRemove.forEach(path => {
                    path.style.visibility = 'hidden';
                });

                textsToRemove.forEach(text => {
                    text.style.visibility = 'hidden';
                });

                svgs.push({
                    element: svgElement,
                    width: width,
                    height: height,
                    title: title,
                });
            });
        }

        if (htmlElements) {
            htmlElements.forEach(({ htmlElement, title }) => {
                let width = Math.max(htmlElement.scrollWidth, htmlElement.offsetWidth);
                const height = Math.max(htmlElement.scrollHeight, htmlElement.offsetHeight);
                const imgsElements = htmlElement.querySelectorAll('img');

                if (imgsElements.length > 0) {
                    imgsElements.forEach((img) => {
                        width = Math.max(img.naturalWidth, width);
                    });
                }

                htmls.push({
                    element: htmlElement,
                    width: width,
                    height: height,
                    title: title,
                });
            });
        }

        if (indicatorElements) {
            await indicatorElements.forEach(async ({
            indicatorElement, title,
            }) => {
                const width = Math.max(indicatorElement.scrollWidth, indicatorElement.offsetWidth);
                const height = Math.max(indicatorElement.scrollHeight, indicatorElement.offsetHeight);


                indicators.push({
                    element: indicatorElement,
                    width: width,
                    height: height,
                    title: title,
                });
            });
        }

        if (tableElements) {
            tableElements.forEach(({ tableElement, title }) => {
                tables.push({
                    element: tableElement,
                    width: tableElement.scrollWidth,
                    height: tableElement.scrollHeight,
                    title: title,
                });
            });
        }

        if (mapsElements) {
            mapsElements.forEach(({ mapElement, title }) => {
                mapas.push({
                    element: mapElement,
                    width: mapElement.width,
                    height: mapElement.height,
                    title: title,
                });
            });
        }

        const pdf = new JsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: 'a4',
        });

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        pdf.setFont('helvetica');
        // eslint-disable-next-line
        const blobToBase64 = (blob) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        };

        const fetchBlobAndConvertToBase64 = async (url) => {
            const response = await fetch(url);
            const blob = await response.blob();
            // eslint-disable-next-line
            return await blobToBase64(blob);
        };

        // eslint-disable-next-line
        const addLogoToPdf = async (logoUrl) => {
            try {
                const logoBase64 = await fetchBlobAndConvertToBase64(logoUrl);

                return new Promise((resolve) => {
                    const img = new Image();

                    img.onload = () => {
                        const maxLogoWidth = 100;
                        const maxLogoHeight = 70;

                        let logoWidth = img.width;
                        let logoHeight = img.height;

                        const widthScale = maxLogoWidth / logoWidth;
                        const heightScale = maxLogoHeight / logoHeight;
                        const scale = Math.min(widthScale, heightScale, 1);

                        logoWidth *= scale;
                        logoHeight *= scale;

                        const y = marginTitle - (logoHeight / 2) + 5;

                        resolve([img, y, logoWidth, logoHeight]);
                    };

                    img.src = logoBase64;
                });
            } catch (error) {
                return 0;
            }
        };

        const logoImg = await addLogoToPdf(logo);

        for (let i = 0; i < mapas.length; i++) {
            const { element, title } = mapas[i];
            let { width, height } = mapas[i];
            const text = title;
            const subText = reportTitle ? `${reportTitle}` : `${i18n.t('REPORT')}`;
            const textHeight = 16;

            pdf.addImage(logoImg[0], 'PNG', 0, logoImg[1], logoImg[2], logoImg[3]);

            const textY = marginTitle - (textHeight / 2) + 10;

            const lineX = logoImg[2] + lineSpacing;

            const lineYStart = textY - 10;
            const lineYEnd = textY + 25;
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineWidth(1.5);
            pdf.line(lineX, lineYStart, lineX, lineYEnd);

            const textX = lineX + textSpacing;

            pdf.setFontSize(fontSize);
            pdf.text(text, textX, textY);
            pdf.text(subText, textX, textY + textHeight + 5);


            let imageWidth = null;
            let imageHeigth = null;

            try {
                // eslint-disable-next-line no-await-in-loop
                const imageDimensions = await this.getImageDimensions(element.imageData);
                imageWidth = imageDimensions[0];
                imageHeigth = imageDimensions[1];
            } catch (e) {
                // do nothing
            }

            let x = 0;
            let y = 0;

            if (imageWidth && imageHeigth) {
                const aspectRatio = imageWidth / imageHeigth;
                const scalar = 0.7;

                width = imageWidth;
                height = imageHeigth;

                if (width > pageWidth || height > pageHeight) {
                    if (width < height) {
                        height = scalar * pageHeight;
                        width = aspectRatio * height;
                    } else {
                        width = scalar * pageWidth;
                        height = (1 / aspectRatio) * width;
                    }
                }

                x = (pageWidth - width) / 2;
                y = (pageHeight - height) / 2;
            }

            pdf.addImage(element.imageData, 'PNG', x, y, width, height);

            if (i !== mapas.length - 1 || svgs.length > 0 || htmls.length > 0 || indicators.length > 0 || tables.length > 0) {
                pdf.addPage();
            }
        }

        for (let i = 0; i < svgs.length; i++) {
            const {
                element, width, height, title,
            } = svgs[i];
            const text = title;
            const subText = reportTitle ? `${reportTitle}` : `${i18n.t('REPORT')}`;
            const textHeight = 16;

            pdf.addImage(logoImg[0], 'PNG', 0, logoImg[1], logoImg[2], logoImg[3]);

            const y = marginTitle - (textHeight / 2) + 10;

            const lineX = logoImg[2] + lineSpacing;

            const lineYStart = y - 10;
            const lineYEnd = y + 25;
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineWidth(1.5);
            pdf.line(lineX, lineYStart, lineX, lineYEnd);

            const textX = lineX + textSpacing;

            pdf.setFontSize(fontSize);
            pdf.text(text, textX, y);
            pdf.text(subText, textX, y + textHeight + 5);


            if (element.querySelector('g') === null) {
                const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');

                const elements = [];
                let { nextSibling } = element.querySelector('rect');
                while (nextSibling) {
                    elements.push(nextSibling);
                    // eslint-disable-next-line
                    nextSibling = nextSibling.nextSibling;
                }
                elements.forEach(el => g.appendChild(el));
                element.appendChild(g);
                element.setAttribute('width', '8000');
                element.setAttribute('height', '6000');
                element.removeAttribute('viewBox');
                const rect = element.querySelector('rect');

                rect.setAttribute('width', '8000');
                rect.setAttribute('height', '6000');
            }

            const gElement = element.querySelector('g');
            const scale = 0.8 * Math.min(pageWidth / width, pageHeight / height, 1);
            const transformValue = `scale(${scale})`;
            gElement.setAttribute('transform', transformValue);

            const clipPathElement = element.querySelector('g[clip-path]');

            let previousClipPath = null;

            if (clipPathElement) {
                previousClipPath = clipPathElement.getAttribute('clip-path');

                clipPathElement.setAttribute('clip-path', 'none');

                let widthClipPath = 0;

                let translateX = 0;
                let translateY = 0;
                let deslocatedElementsX = 0;
                let countDeslocatedElementsX = 0;
                let deslocatedElementsY = 0;
                let countDeslocatedElementsY = 0;

                clipPathElement.childNodes.forEach(child => {
                    if (child.getBBox) {
                        const bbox = child.getBBox();
                        widthClipPath += bbox.width;
                    }

                    const transformValueX = child.getAttribute('transform');
                    let translateMatch;
                    if (transformValueX) {
                        translateMatch = transformValueX.match(/translate\(([^)]+)\)/);
                    }
                    if (translateMatch) {
                        const x = translateMatch[1].split(' ').map(Number)[0];
                        const y = translateMatch[1].split(' ').map(Number)[1];
                        if (x < 0) {
                            deslocatedElementsX += x;
                            countDeslocatedElementsX++;
                        }
                        if (y < 0) {
                            deslocatedElementsY += y;
                            countDeslocatedElementsY++;
                        }
                    }
                });

                if (countDeslocatedElementsX > 0) {
                    translateX = 0.4 * Math.abs(deslocatedElementsX / countDeslocatedElementsX) + 100;
                }
                if (countDeslocatedElementsY > 0) {
                    translateY = 0.4 * Math.abs(deslocatedElementsY / countDeslocatedElementsY) + 30;
                }

                let scaleValue = 1;

                if (widthClipPath > pageWidth) {
                    scaleValue = pageWidth / widthClipPath;
                }

                clipPathElement.setAttribute('transform', `translate(${translateX}, ${translateY}) scale(${scaleValue})`);
            }

            // eslint-disable-next-line no-await-in-loop
            await pdf.svg(element, {
                x: (pageWidth - (scale * width)) / 2,
                y: clipPathElement ? 2 * marginY : marginY,
                width: pageWidth,
                height: pageHeight,
            });

            if (clipPathElement) {
                // eslint-disable-next-line no-await-in-loop
                await clipPathElement.setAttribute('clip-path', previousClipPath);
                // eslint-disable-next-line no-await-in-loop
                await clipPathElement.removeAttribute('transform');
            }

            gElement.removeAttribute('transform');

            if (i !== svgs.length - 1 || htmls.length > 0 || indicators.length > 0 || tables.length > 0) {
                pdf.addPage();
            }
        }

        for (let i = 0; i < htmls.length; i++) {
            const {
                element, width, height, title,
            } = htmls[i];
            const text = title;
            const subText = reportTitle ? `${reportTitle}` : `${i18n.t('REPORT')}`;
            const textHeight = 16;

            pdf.addImage(logoImg[0], 'PNG', 0, logoImg[1], logoImg[2], logoImg[3]);

            const y = marginTitle - (textHeight / 2) + 10;


            const lineX = logoImg[2] + lineSpacing;

            const lineYStart = y - 10;
            const lineYEnd = y + 25;
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineWidth(1.5);
            pdf.line(lineX, lineYStart, lineX, lineYEnd);

            const textX = lineX + textSpacing;

            pdf.setFontSize(fontSize);
            pdf.text(text, textX, y);
            pdf.text(subText, textX, y + textHeight + 5);

            const positionY = (pdf.getNumberOfPages() - 1) * pdf.internal.pageSize.height;

            const sizeContent = 1.2 * Math.max(width, height);

            const options = {
                x: 10,
                y: marginY + positionY + 30,
                width: pageWidth,
                height: pageHeight,
                windowWidth: sizeContent + 2 * marginX > 1500 ? 1500 : sizeContent + 2 * marginX,
            };

            if (element.className.includes('ql-editor')) {
                options.windowWidth = pageWidth;
                options.width = pageWidth;

                let textHeight = marginY;

                const childElements = element.children;
                for (let j = 0; j < childElements.length; j++) {
                    textHeight += 10;
                    if (childElements[j].textContent.trim().length > 0) {
                        const contentHeight = pdf.getTextDimensions(childElements[j].outerText, { maxWidth: pageWidth - 20 }).h;
                        if (textHeight + contentHeight > pageHeight - 10) {
                            pdf.addPage();
                            textHeight = 10;
                        }
                        const textContent = childElements[j].outerText;
                        pdf.setFontSize(12);
                        pdf.text(textContent, 10, textHeight + 10, { maxWidth: pageWidth - 20, charSpace: 0 });
                        textHeight += pdf.getTextDimensions(childElements[j].outerText, { maxWidth: pageWidth - 20 }).h;
                    }

                    const imgsElements = childElements[j].querySelectorAll('img');
                    if (imgsElements.length > 0) {
                        textHeight += 10;
                        for (let k = 0; k < imgsElements.length; k++) {
                            const scale = (pageWidth - marginX) / imgsElements[k].naturalWidth;

                            imgsElements[k].style.width = `${pageWidth - marginX}px`;
                            imgsElements[k].style.height = `${imgsElements[k].naturalHeight * scale}px`;

                            if (textHeight + imgsElements[k].naturalHeight * scale > pageHeight - 10) {
                                textHeight = 10;
                                pdf.addPage();
                            }

                            options.y = (pdf.getNumberOfPages() - 1) * pageHeight + textHeight;
                            textHeight += imgsElements[k].naturalHeight * scale;
                            // eslint-disable-next-line no-await-in-loop
                            await pdf.html(imgsElements[k], options);
                        }
                    }
                }
            } else {
                try {
                    pdf.autoTable({
                        html: element,
                        startY: marginY + 30,
                        margin: { top: 40 },
                        styles: {
                            cellPadding: 5,
                            fontSize: 8,
                            halign: 'left',
                            valign: 'middle',
                            fillColor: '#ebf0f8',
                            lineColor: '#c8d4e3',
                            lineWidth: 0.05,
                            cellWidth: 'wrap',
                            minCellHeight: 30,
                            minCellWidth: 0,
                            maxCellWidth: 80,
                            textColor: [0, 0, 0],
                            overflow: 'linebreak',
                            fontStyle: 'bold',
                        },
                        headStyles: {
                            fillColor: '#ebf0f8',
                            textColor: [0, 0, 0],
                            fontStyle: 'bold',
                            lineColor: '#c8d4e3',
                            lineWidth: 0.05,
                        },
                        alternateRowStyles: {
                            fillColor: '#ebf0f8',
                            lineColor: '#c8d4e3',
                            lineWidth: 0.05,
                            fontStyle: 'bold',
                        },
                        didParseCell: function (data) {
                            if (data.cell.raw.classList.contains('pvtTotal') || data.cell.raw.classList.contains('rowTotal') || data.cell.raw.classList.contains('pvtVal') || data.cell.raw.classList.contains('pvtGrandTotal')) {
                                data.cell.styles.fillColor = [255, 255, 255];
                                data.cell.styles.halign = 'center';
                                data.cell.styles.fontStyle = 'normal';
                                data.cell.styles.lineColor = '#CDCDCD';
                            }
                            if (data.cell.raw.classList.contains('pvtTotalLabel') && (data.cell.raw.classList.contains('pvtRowTotalLabel') || data.cell.raw.classList.contains('pvtColTotalLabel'))) {
                                data.cell.styles.halign = 'right';
                            }
                        },
                    });
                } catch (e) {
                    const svgDocument = elementToSVG(element, {
                        ignoreStyle: true,
                    });
                    const svgElement = svgDocument.documentElement;
                    // eslint-disable-next-line no-await-in-loop
                    await pdf.svg(svgElement, {
                        x: 10,
                        y: marginY + 30,
                        width: pageWidth,
                        height: pageHeight / 2,
                        windowWidth: sizeContent + 2 * marginX > 1500 ? 1500 : sizeContent + 2 * marginX,
                    });
                }
            }

            if (i !== htmls.length - 1 || indicators.length > 0 || tables.length > 0) {
                pdf.addPage();
            }
        }

        for (let i = 0; i < tables.length; i++) {
            const {
                element,
                title,
            } = tables[i];
            const text = title;
            const subText = reportTitle ? `${reportTitle}` : `${i18n.t('REPORT')}`;
            const textDimensions = pdf.getTextDimensions(text);
            const textHeight = textDimensions.h;

            pdf.addImage(logoImg[0], 'PNG', 0, logoImg[1], logoImg[2], logoImg[3]);

            const y = marginTitle - (textHeight / 2) + 10;

            const lineX = logoImg[2] + lineSpacing;

            const lineYStart = y - 10;
            const lineYEnd = y + 25;
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineWidth(1.5);
            pdf.line(lineX, lineYStart, lineX, lineYEnd);

            const textX = lineX + textSpacing;

            pdf.setFontSize(fontSize);
            pdf.text(text, textX, y);
            pdf.text(subText, textX, y + textHeight + 5);

            const headerElement = element.querySelector('.v-card.v-sheet.theme--light.primary');
            if (headerElement) {
                headerElement.style.backgroundColor = '#3F7EAC';
            }

            const rowElement = element.querySelector('.row.align-center.justify-end');
            if (rowElement) {
                rowElement.style.display = 'none';
            }

            element.querySelectorAll('*').forEach(el => {
                el.style.fontFamily = '"Helvetica", normal';
            });
            const svgDocument = elementToSVG(element);
            const svgElement = svgDocument.documentElement;
            const scale = 0.9;
            const transformValue = `scale(${scale})`;
            svgElement.setAttribute('transform', transformValue);
            // eslint-disable-next-line no-await-in-loop
            await pdf.svg(svgElement, {
                x: 20,
                y: 20,
                width: pageWidth,
                height: pageHeight,
            });

            if (i !== tables.length - 1 || indicators.length > 0) {
                pdf.addPage();
            }
        }

        for (let i = 0; i < indicators.length; i++) {
            const {
                element, title,
            } = indicators[i];
            const text = title;
            const subText = reportTitle ? `${reportTitle}` : `${i18n.t('REPORT')}`;
            let textHeight = 0;
            if (text) {
                const textDimensions = pdf.getTextDimensions(text);
                textHeight = textDimensions.h;
            }

            pdf.addImage(logoImg[0], 'PNG', 0, logoImg[1] + 15, logoImg[2], logoImg[3]);

            const y = marginTitle - (textHeight / 2) + 10;

            const lineX = logoImg[2] + lineSpacing;

            const lineYStart = y - 10;
            const lineYEnd = y + 25;
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineWidth(1.5);
            pdf.line(lineX, lineYStart, lineX, lineYEnd);

            const textX = lineX + textSpacing;

            pdf.setFontSize(fontSize);
            if (text) {
                pdf.text(text, textX, y);
                pdf.text(subText, textX, y + textHeight + 5);
            } else {
                pdf.text(subText, textX, y + 15);
            }

            pdf.setFontSize(fontSize);

            let elementsIndicatorsButton;
            if (element.$el) {
                // eslint-disable-next-line no-await-in-loop
                elementsIndicatorsButton = await element.$el.querySelectorAll('button');
            } else {
                // eslint-disable-next-line no-await-in-loop
                elementsIndicatorsButton = await element.querySelectorAll('button');
            }

            elementsIndicatorsButton.forEach(el => {
                el.style.display = 'none';
            });

            let elementsIndicatorsIcon;
            if (element.$el) {
                // eslint-disable-next-line no-await-in-loop
                elementsIndicatorsIcon = await element.$el.querySelectorAll('i');
            } else {
                 // eslint-disable-next-line no-await-in-loop
                elementsIndicatorsIcon = await element.querySelectorAll('i');
            }

            elementsIndicatorsIcon.forEach(el => {
                el.style.display = 'none';
            });

            let cards;
            window.$ = require('jquery');
            window.jqueryui = require('jqueryui');
            if (element.indicator) {
                if (element.indicator.color === 'exati-blue-main') {
                    $(element.$children[0].$el).css('background-color', 'blue');
                } else if (element.indicator.color === 'warning') {
                    $(element.$children[0].$el).css('background-color', 'yellow');
                } else {
                    $(element.$children[0].$el).css('background-color', element.indicator.color);
                }
            }
            if (element.$el) {
                if (element.color === 'exati-blue-main') {
                    $(element.$el).css('background-color', 'blue');
                } else if (element.color === 'warning') {
                    $(element.$el).css('background-color', 'yellow');
                } else {
                    $(element.$el).css('background-color', element.color);
                }
            } else {
                 cards = element.querySelectorAll('.indicator-card');
                 for (let k = 0; k < cards.length; k++) {
                    if (cards[k].classList.contains('green')) {
                        cards[k].style.backgroundColor = 'green';
                    } else if (cards[k].classList.contains('purple')) {
                        cards[k].style.backgroundColor = 'purple';
                    } else if (cards[k].classList.contains('exati-blue-main')) {
                        cards[k].style.backgroundColor = 'blue';
                    } else if (cards[k].classList.contains('red')) {
                        cards[k].style.backgroundColor = 'red';
                    } else if (cards[k].classList.contains('pink')) {
                        cards[k].style.backgroundColor = 'pink';
                    } else if (cards[k].classList.contains('cyan')) {
                        cards[k].style.backgroundColor = 'cyan';
                    } else if (cards[k].classList.contains('warning')) {
                        cards[k].style.backgroundColor = 'yellow';
                    } else {
                        cards[k].style.backgroundColor = 'grey';
                    }
                }
            }
            if (element.$el) {
                const svgDocument = elementToSVG(element.$el);
                const svgElement = svgDocument.documentElement;
                svgElement.setAttribute('width', '100');
                svgElement.setAttribute('height', '100');
                const scale = 0.5;
                const transformValue = `scale(${scale})`;
                svgElement.setAttribute('transform', transformValue);
                // eslint-disable-next-line no-await-in-loop
                await pdf.svg(svgElement, {
                    x: 5,
                    y: 0,
                    width: pageWidth,
                    height: pageHeight,
                });
            } else {
                const svgDocument = elementToSVG(element);
                const svgElement = svgDocument.documentElement;
                const scale = 0.8;
                const transformValue = `scale(${scale})`;
                svgElement.setAttribute('transform', transformValue);
                // eslint-disable-next-line no-await-in-loop
                await pdf.svg(svgElement, {
                    x: 5,
                    y: marginTitle * 3,
                    width: pageWidth,
                    height: pageHeight,
                });
            }

            if (i !== indicators.length - 1) {
                pdf.addPage();
            }
        }


        pdf.save(fileName);
    },
    updateDashboardFilters: function (command, newfilters) {
        if (command) {
            const REGEX_INITIAL_DATE = /DATA.*(INICIO|INICIAL)|\b(INICIO|INICIAL).*DATA\b/i;
            const REGEX_END_DATE = /DATA.*(FIM|FINAL|TERMINO|CONCLUSAO|FINALIZACAO|ULTIMA)|\b(FIM|FINAL|TERMINO|CONCLUSAO|FINALIZACAO|ULTIMA).*DATA\b/i;
            const REGEX_WORKSITE_ID = /^(ID_PARQUE_SERVICO|IDS_PARQUE_SERVICO)$/;

            const { fields, _fields, params } = command;
            const commandFields = fields || _fields || params;

            let fieldsKeyString;
            if (params) {
                fieldsKeyString = 'params';
            }
            if (fields) {
                fieldsKeyString = 'fields';
            }
            if (_fields) {
                fieldsKeyString = '_fields';
            }

            const scriptParameters = {};
            if (commandFields) {
                Object.entries(commandFields).forEach(([fieldKeyString, { name, value, _value }]) => {
                    const mutableValue = value || _value;
                    const valueKeyString = value || params ? 'value' : '_value';

                    if (REGEX_INITIAL_DATE.test(name)) {
                        if (mutableValue?.type && mutableValue.type === 'DATE') {
                            command[fieldsKeyString][fieldKeyString][valueKeyString].date = newfilters.initialDate;
                        } else {
                            command[fieldsKeyString][fieldKeyString][valueKeyString] = newfilters.initialDate;
                        }
                    }
                    if (REGEX_END_DATE.test(name)) {
                        if (mutableValue?.type && mutableValue.type === 'DATE') {
                            command[fieldsKeyString][fieldKeyString][valueKeyString].date = newfilters.endDate;
                        } else {
                            command[fieldsKeyString][fieldKeyString][valueKeyString] = newfilters.endDate;
                        }
                    }
                    if (REGEX_WORKSITE_ID.test(name)) {
                        if (mutableValue && Array.isArray(mutableValue) && newfilters.worksiteId) {
                            command[fieldsKeyString][fieldKeyString][valueKeyString] = [newfilters.worksiteId];
                        } else {
                            command[fieldsKeyString][fieldKeyString][valueKeyString] = newfilters.worksiteId;
                        }
                    }

                    if (newfilters !== null && Array.isArray(newfilters)) {
                        newfilters.forEach(filter => {
                            if (name === filter.name && filter.value) {
                                command[fieldsKeyString][fieldKeyString][valueKeyString] = filter.value;
                                scriptParameters[fieldKeyString] = filter.value;
                            }
                        });
                    }
                });

                const { value } = command[fieldsKeyString].SCRIPT_PARAMETERS;
                const valueKeyString = value || params ? 'value' : '_value';
                let currentScriptParameters = command[fieldsKeyString].SCRIPT_PARAMETERS[valueKeyString];
                currentScriptParameters = JSON.parse(currentScriptParameters) || {};
                const newScriptParameters = { ...currentScriptParameters, ...scriptParameters };
                command[fieldsKeyString].SCRIPT_PARAMETERS[valueKeyString] = JSON.stringify(newScriptParameters);
            }
        }
    },
    fileToUint8Array: function (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function (event) {
                const arrayBuffer = event.target.result;
                const uint8Array = new Uint8ClampedArray(arrayBuffer);
                const image = new Image();
                image.onload = function () {
                    resolve({ uint8Array: uint8Array, width: image.width, height: image.height });
                };
                image.onerror = function (error) {
                    reject(error);
                };
                image.src = URL.createObjectURL(file);
            };

            reader.onerror = function (error) {
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    },
    readQRCodeFromFile: async function (file) {
        const result = await this.fileToUint8Array(file);
        const decoded = png.decode(result.uint8Array);

        const { width, height } = decoded;
        const imageData = png.toRGBA8(decoded);
        const array = new Uint8ClampedArray(imageData[0]);

        const code = jsQR(array, width, height);
        if (code != null && (code.data == null || !code.data.length) && code.binaryData != null && code.binaryData.length) {
            const textDecoder = new TextDecoder('ISO-8859-1');
            const decodedString = textDecoder.decode(new Uint8Array(code.binaryData));
            code.data = decodedString;
        }
        return code;
    },

    isUpperCase: function (string) {
        return string === string.toUpperCase();
    },

    isComposedBySetsOf2Characters: function (string) {
        const array = string.split(':');
        return array.every((part) => part.length === 2);
    },

    allCharactersAreHex: function (string) {
        const alphabet = '0123456789ABCDEF:';
        const array2 = string.split('');
        return array2.every((part) => alphabet.includes(part));
    },

    validateBoardSerialNumber: function (serialNumber) {
        return this.isUpperCase(serialNumber)
        && this.isComposedBySetsOf2Characters(serialNumber)
        && this.allCharactersAreHex(serialNumber);
    },


    transverseObject: function (obj1, parent1) {
        let nextId = 2;
        function transverseRecursive (obj, parent) {
            Object.keys(obj).forEach((key) => {
                if (typeof obj[key] === 'object') {
                    const newParent = {
                        id: nextId++,
                        key: key,
                        children: [],
                    };
                    parent.children.push(newParent);
                    transverseRecursive(obj[key], newParent);
                } else {
                    parent.children.push({
                        id: nextId++,
                        key: key,
                        value: obj[key],
                        editMode: false,
                    });
                }
            });
        }
        transverseRecursive(obj1, parent1);
    },

    getEnUSLocale: function (timestamp) {
        const { timeZone } = this.getBrowserTimezone();
        return new Date(timestamp).toLocaleString('en-US', { timeZone: timeZone });
    },

    getBrowserTimezone: function () {
        return Intl.DateTimeFormat().resolvedOptions();
    },

    isString: function (variable) {
        return typeof variable === 'string' || variable instanceof String;
    },

    getRoutingMarker: function (activity, team, activityIndex, listPointsConfirmedMaintenance, listPointsAttendedLate, listPointsPendingSync, listPointsRejected, rendered = false, spriteCallback) {
        const { $moment } = Vue.prototype;
        const activityDue = $moment(activity.due_date);
        const activityArrival = $moment(activity.arrival_time);
        const teamDue = $moment(team.end_time);

        let strokeColor = '#EF5549';
        if (activityDue.isAfter(activityArrival)) {
            strokeColor = activityDue.isAfter(teamDue) ? '#52BE7F' : '#F9A726';
        }


        const activityId = Number(activity.id.split('.')[0]);
        if (listPointsConfirmedMaintenance.includes(activityId)) {
            strokeColor = '#004AA3';
        }
        if (listPointsAttendedLate.includes(activityId)) {
            strokeColor = '#8CBCFF';
        }
        if (listPointsPendingSync.includes(activityId)) {
            strokeColor = '#CAB4FC';
        }
        if (listPointsRejected.includes(activityId)) {
            strokeColor = '#E6E6E6';
        }

        const issue = this.issuesById ? this.issuesById[activity.id] || {} : {};
        const label = `${issue.NUMERO_PROTOCOLO || activity.id}`;
        let tooltip = `${i18n.t('ISSUE')}: ${label}`;
        tooltip += `\n${i18n.t('TEAM')}: ${team.name}`;
        tooltip += `\n${i18n.t('ACTIVITY')}: ${$moment(activity.arrival_time).format('DD/MM/YYYY HH:mm:ss')} ${i18n.t('TO_2').toLowerCase()} ${$moment(activity.end_time).format('HH:mm:ss')}`;
        tooltip += `\n${i18n.t('DEADLINE')}: ${$moment(activity.due_date).format('DD/MM/YYYY HH:mm:ss')}`;

        return {
            ...activity,
            id: uuidv4(),
            mapMarker: {
                lat: Number(activity.location.latitude),
                lng: Number(activity.location.longitude),
                spriteCallBack: spriteCallback,
                index: rendered ? null : activityIndex + 1,
                color: strokeColor,
                strokeColor: team?.color,
                tooltip: {
                    content: tooltip,
                },
            },
        };
    },
};

export default util;
