import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
const { $autocomplete, $select, $util, } = Vue.prototype;
export class GetLightSpots extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPontosLuminosos',
            type: 'SEARCH',
            node: 'PONTOS_LUMINOSOS.PONTO_LUMINOSO',
            fields: {
                worksiteId: new Field({
                    name: 'IDS_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                        multiple: true,
                    },
                }),
                attributeSchemaId1: new Field({
                    name: 'ID_ESQUEMA_ATRIBUTOS',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ATTRIBUTE_TYPE'),
                        entity: $select.attributesScheme,
                        'entity-params': {
                            SGI: 0,
                            LAUDO: 0,
                        },
                    },
                }),
                attributeFilter1: new Field({
                    name: 'ID_ATRIBUTO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ATTRIBUTE'),
                        entity: $select.attribute,
                        'entity-params': { SGI: 1 },
                        'require-params': ['ID_ESQUEMA_ATRIBUTOS'],
                        clearable: true,
                        'reload-provider': true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_ESQUEMA_ATRIBUTOS',
                            handler: (fields) => {
                                fields.attributeFilter1.props['entity-params'] = {
                                    SGI: 1,
                                    ID_ESQUEMA_ATRIBUTOS: fields.attributeSchemaId1.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_ESQUEMA_ATRIBUTOS',
                            handler: (fields) => {
                                fields.attributeFilter1.props.disabled = !fields.attributeSchemaId1.value;
                                if (fields.attributeFilter1.props.disabled) {
                                    fields.attributeFilter1.value = null;
                                }
                            },
                        },
                    ],
                }),
                attributeSchemaId2: new Field({
                    name: 'ID_ESQUEMA_ATRIBUTOS2',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ATTRIBUTE_TYPE_2'),
                        entity: $select.attributesScheme,
                        'entity-params': {
                            SGI: 0,
                            LAUDO: 0,
                        },
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_ESQUEMA_ATRIBUTOS',
                            handler: (fields) => {
                                if (fields.attributeSchemaId2.value == null) {
                                    fields.attributeSchemaId2.value = fields.attributeSchemaId1.value;
                                }
                            },
                        },
                    ],
                }),
                attributeFilter2: new Field({
                    name: 'ID_ATRIBUTO2',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ATTRIBUTE_2'),
                        entity: $select.attribute,
                        'entity-params': {},
                        'require-params': ['ID_ESQUEMA_ATRIBUTOS'],
                        clearable: true,
                        'reload-provider': true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_ESQUEMA_ATRIBUTOS2',
                            handler: (fields) => {
                                fields.attributeFilter2.props['entity-params'] = {
                                    SGI: 1,
                                    ID_ESQUEMA_ATRIBUTOS: fields.attributeSchemaId2.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_ESQUEMA_ATRIBUTOS2',
                            handler: (fields) => {
                                fields.attributeFilter2.props.disabled = !fields.attributeSchemaId2.value;
                                if (fields.attributeFilter2.props.disabled) {
                                    fields.attributeFilter2.value = null;
                                }
                            },
                        },
                    ],
                }),
                assetTypeId: new Field({
                    name: 'ID_TIPO_PONTO_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ASSET_TYPE'),
                        entity: $autocomplete.assetType,
                        clearable: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'IDS_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.assetTypeId.props['entity-params'] = {
                                    ...fields.assetTypeId.props['entity-params'],
                                    IDS_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                validAssets: new Field({
                    name: 'PONTOS_VALIDOS',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('VALID_ASSETS'),
                        entity: $select.validAssets,
                    },
                }),
                assetStatusId: new Field({
                    name: 'ID_STATUS_PONTO_SERVICO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('STATUS'),
                        entity: $select.assetStatus,
                    },
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $select.regions,
                        clearable: true,
                        'reload-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_PARQUE_SERVICO', 'ID_MUNICIPIO'],
                            handler: function () {
                                this.fields.regionId.value = null;
                                this.fields.regionId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: this.fields.worksiteId.value,
                                    SOMENTE_ATIVOS: 1,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: function () {
                                this.fields.regionId.props.disabled = !this.fields.worksiteId.value;
                            },
                        },
                    ],
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('DISTRICT'),
                        entity: $autocomplete.district,
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_REGIAO'],
                            handler: (fields) => {
                                fields.districtId.props['entity-params'] = {
                                    ID_REGIAO: fields.regionId.value,
                                };
                            },
                        },
                    ],
                }),
                publicPlaceId: new Field({
                    name: 'ID_LOGRADOURO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('PUBLIC_PLACE'),
                        entity: $autocomplete.publicPlace,
                        'require-params': ['ID_BAIRRO'],
                        clearable: true,
                        'reload-provider': true,
                        'clear-provider': true,
                        'item-text': 'NOME_LOGRADOURO_COMPLETO',
                        '_min-to-search': 2,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_BAIRRO',
                            handler: function () {
                                this.fields.publicPlaceId.props.disabled = !this.fields.districtId.value;
                            },
                        },
                        {
                            fieldName: 'ID_BAIRRO',
                            handler: function () {
                                this.fields.publicPlaceId.props['entity-params'] = {
                                    ID_BAIRRO: this.fields.districtId.value,
                                };
                            },
                        },
                    ],
                }),
                agrouppingId: new Field({
                    name: 'ID_AGRUPAMENTO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('GROUPING'),
                        entity: $select.groupingLocation,
                        clearable: true,
                        'reload-provider': true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: ['ID_BAIRRO', 'ID_REGIAO'],
                            handler: function () {
                                this.fields.agrouppingId.props['entity-params'] = {
                                    ID_BAIRRO: this.fields.districtId.value,
                                    ID_REGIAO: this.fields.regionId.value,
                                };
                            },
                        },
                    ],
                }),
                measured: new Field({
                    name: 'CALC_MEDIDOS',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('MEASURED'),
                        entity: $select.yesNoAll,
                        clearable: false,
                    },
                }),
                attributeFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _general: null,
                        _docs: null,
                        _report: null,
                        _sgi: 1,
                        _filter: false,
                    },
                }),
                pdf: new Field({
                    name: 'RELATORIO_PDF',
                }),
                reportId: new Field({
                    name: 'ID_RELATORIO',
                }),
                situation: new Field({
                    name: 'SITUATION',
                }),
                pageSize: new Field({
                    name: 'PAGE_SIZE',
                }),
                page: new Field({
                    name: 'PAGE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
