import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
export class SaveDashboard extends CallCommand {
    constructor() {
        super({
            name: 'SalvarDashboardUsuario',
            type: 'SAVE',
            fields: {
                dashboardId: new Field({
                    name: 'ID_DASHBOARD',
                }),
                name: new Field({
                    name: 'NOME',
                }),
                reports: new Field({
                    name: 'RELATORIOS',
                    parser: JSON.stringify,
                }),
                originalDashboardId: new Field({
                    name: 'ID_DASHBOARD_ORIGINAL',
                }),
                userId: new Field({
                    name: 'ID_USUARIO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ShareDashboard extends CallCommand {
    constructor() {
        super({
            name: 'CompartilharDashboardUsuario',
            type: 'SAVE',
            fields: {
                name: new Field({
                    name: 'NOME',
                }),
                reports: new Field({
                    name: 'RELATORIOS',
                    parser: JSON.stringify,
                }),
                originalDashboardId: new Field({
                    name: 'ID_DASHBOARD_ORIGINAL',
                }),
                userId: new Field({
                    name: 'ID_USUARIO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class ExportDashboard extends CallCommand {
    constructor() {
        super({
            name: 'ExportarDashboardUsuario',
            type: 'FILE',
            config: { type: 'file' },
            fields: {
                dashboardId: new Field({
                    name: 'ID_DASHBOARD',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class RemoveDashboard extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirDashboardUsuario',
            type: 'DELETE',
            fields: {
                dashboardId: new Field({
                    name: 'ID_DASHBOARD',
                }),
                permission: new Field({
                    name: 'PERMISSAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDashboards extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDashboardUsuario',
            type: 'SEARCH',
            node: 'DASHBOARDS.DASHBOARD',
            fields: {
                userId: new Field({
                    name: 'ID_USUARIO',
                }),
                dashboardId: new Field({
                    name: 'ID_DASHBOARD',
                }),
                shared: new Field({
                    name: 'COMPARTILHADO',
                }),
                getUrl: new Field({
                    name: 'GET_URL',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetContributorsUserCompany extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarColaboradoresEmpresaUsuaria',
            type: 'NONE',
            node: 'COLABORADORES.COLABORADOR',
            fields: {
                companyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                }),
                name: new Field({
                    name: 'NOME',
                }),
                active: new Field({
                    name: 'ATIVO',
                }),
                permissionGroupId: new Field({
                    name: 'ID_GRUPO_PERMISSOES',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveDashboardConfig extends CallCommand {
    constructor() {
        super({
            name: 'SalvarConfiguracaoDashboardUsuario',
            type: 'SAVE',
            fields: {
                dashboardId: new Field({
                    name: 'ID_DASHBOARD',
                }),
                emissionHour: new Field({
                    name: 'HORARIO_EMISSAO',
                }),
                emailList: new Field({
                    name: 'EMAILS',
                }),
                subject: new Field({
                    name: 'ASSUNTO',
                }),
                message: new Field({
                    name: 'MENSAGEM',
                }),
                emissionType: new Field({
                    name: 'TIPO_EMISSAO',
                }),
                emissionWeekDays: new Field({
                    name: 'DIA_SEMANA_EMISSAO',
                }),
                emissionDate: new Field({
                    name: 'DIA_MES_EMISSAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class EmitDashboardNow extends CallCommand {
    constructor() {
        super({
            name: 'EmitirRelatoriosDashboardUsuarioParaAgora',
            type: 'SAVE',
            fields: {
                dashboardId: new Field({
                    name: 'ID_DASHBOARD',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDashboardPermissions extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPermissoesDashboard',
            type: 'SEARCH',
            node: 'PERMISSOES.PERMISSAO',
            fields: {
                dashboardId: new Field({
                    name: 'ID_DASHBOARD',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveDashboardPermissions extends CallCommand {
    constructor() {
        super({
            name: 'AlterarPermissoesUsuariosDashboard',
            type: 'SAVE',
            fields: {
                dashboardId: new Field({
                    name: 'ID_DASHBOARD',
                }),
                permissionsGroupsJson: new Field({
                    name: 'JSON_GRUPOS_PERMISSOES',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDashboardPermission extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarDashboardPermissao',
            type: 'SEARCH',
            node: 'DASHBOARDS.DASHBOARD',
            fields: {
                dashboardId: new Field({
                    name: 'ID_DASHBOARD',
                }),
                groupId: new Field({
                    name: 'ID_GRUPO_PERMISSOES',
                }),
                userId: new Field({
                    name: 'ID_USUARIO',
                }),
                searchPermission: new Field({
                    name: 'CONSULTAR_PERMISSOES',
                }),
                searchUsers: new Field({
                    name: 'CONSULTAR_USUARIOS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveDashboardPermission extends CallCommand {
    constructor() {
        super({
            name: 'SalvarDashboardPermissao',
            type: 'SAVE',
            fields: {
                dashboardPermissionGroupId: new Field({
                    name: 'ID_DASHBOARD_PERMISSAO',
                }),
                dashboardId: new Field({
                    name: 'ID_DASHBOARD',
                }),
                permission: new Field({
                    name: 'PERMISSAO',
                }),
                permissionsGroups: new Field({
                    name: 'ARRAY_GRUPOS_PERMISSOES',
                }),
                userId: new Field({
                    name: 'ID_USUARIO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class RemoveDashboardPermission extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirDashboardPermissao',
            type: 'DELETE',
            fields: {
                dashboardPermissionGroup: new Field({
                    name: 'ID_DASHBOARD_PERMISSAO',
                }),
                groupId: new Field({
                    name: 'ID_GRUPO_PERMISSOES',
                }),
                dashboardId: new Field({
                    name: 'ID_DASHBOARD',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveDashboardCustomGlobalFilters extends CallCommand {
    constructor() {
        super({
            name: 'SalvarFiltrosGlobaisCustomizadosDashboard',
            type: 'SAVE',
            fields: {
                dashboardId: new Field({
                    name: 'ID_DASHBOARD',
                }),
                filters: new Field({
                    name: 'FILTROS_GLOBAIS_CUSTOMIZADOS',
                    parser: JSON.stringify,
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
