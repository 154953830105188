const paths = [
  {
    path: '/stock-integration',
    name: 'StockIntegration',
    title: 'STOCK_INTEGRATION',
    icon: 'widgets',
    menu: 'stock',
    categories: [
      'configurations',
    ],
    component: () => import(/* webpackChunkName: "stockIntegration" */ /* webpackMode: "lazy" */ '@/pages/stockIntegration/StockIntegration.vue'),
  },
  {
    path: '/stock-integration-customizations',
    title: 'STOCK_INTEGRATION',
    name: 'StockIntegrationCustomizations',
    component: () => import(/* webpackChunkName: "stockIntegration" */ /* webpackMode: "lazy" */ '@/pages/stockIntegration/StockIntegrationCustomizations.vue'),
  },
  {
    path: '/stock-integration-customization-configuration',
    title: 'STOCK_INTEGRATION',
    name: 'StockIntegrationCustomizationConfiguration',
    component: () => import(/* webpackChunkName: "stockIntegration" */ /* webpackMode: "lazy" */ '@/pages/stockIntegration/StockIntegrationCustomizationConfiguration.vue'),
  },
  {
    path: '/stock-integration-user-company-external-code',
    title: 'STOCK_INTEGRATION',
    name: 'StockIntegrationUserCompanyExternalCode',
    component: () => import(/* webpackChunkName: "stockIntegration" */ /* webpackMode: "lazy" */ '@/pages/stockIntegration/StockIntegrationUserCompanyExternalCode.vue'),
  },
];

export default paths;
