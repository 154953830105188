import moment from 'moment';
import axios from 'axios';
import events from '../util/events/events';
import notification from '../notification/notification';
import urlProvider from './requestURL';
import merge from '../util/helper/merge';
import getArray from '../util/helper/getArray';
import log from '../util/log/log';
import i18n from '@/i18n';
import alert from '../alert/alert';
import store from '../../../../store/store.js';

const REPORT_TYPE = 4;
const MESSAGE_TYPE = 1;

const request = {
    options: {},
    runningRequests: 0,
    initializeOptions: function (options) {
        const aux = {
            requestHeader: { headers: { 'Content-Type': 'application/x-ww-form-urlencoded' } },
            context: '',
            url: '',
            debug: {
                actived: false,
                url: 'http://localhost/guia/contrel/command/contrel/',
                log: false,
            },
        };
        this.options = merge(aux, [options]);
    },
    isDebug: function () {
        return (this.options.debug && this.options.debug.log);
    },
    getHTTPUrl: function () {
        return urlProvider.getHTTPUrl(this.options.debug.actived ? this.options.debug.url : this.options.url || null, this.options.context);
    },
    setHTTPUrl: function (url) {
        this.options.url = url;
    },
    handleResult: function (value, silent, ignoreLogout=false) {
        if (!value.RAIZ && value.data && value.data.RAIZ) {
            value = value.data;
        }
        if (!silent) {
            notification.showNotifications(value.RAIZ.MESSAGES);
        }
        if (value.RAIZ.LOGIN_REQUEST && parseInt(value.RAIZ.RESULT, 10) === -1) {
            if (!ignoreLogout) {
                localStorage.removeItem('token');
                events.toggleLogin(true);
            }
        }
        const notifications = getArray(() => value.RAIZ.NOTIFICATIONS);
        if (notifications.some(notification => notification.TYPE === REPORT_TYPE)) {
            store.dispatch('ADDED_REPORT');
        }

        const messages = notifications.filter(notification => notification.TYPE === MESSAGE_TYPE)
        if (messages.length > 0) {
            store.dispatch('addUserChatMessages', messages)
        }

        return value.RAIZ.RESULT;
    },
    getFormData: function (parameters, config) {
        const formData = new FormData();

        Object.keys(parameters).forEach((key) => {
            const param = parameters[key];
            if (param !== null && typeof param === 'object' && param.value) {
                const cmd = !(param.prependCMD === false || config.prependCMD === false);
                cmd ? formData.append(`CMD_${key}`, param.value) : formData.append(key, param.value);
            } else {
                const value = parameters[key];
                if (value !== null && value !== undefined) {
                    const cmd = !(config.prependCMD === false);
                    cmd ? formData.append(`CMD_${key}`, value) : formData.append(key, value);
                }
            }
        });
        formData.append('parser', config.parser || 'json');
        formData.append("CMD_PLATFORM_DEVICE", "WEB");
        formData.append("CMD_LOCALE_INTERFACE", i18n.locale);

        return formData;
    },
    getParametersNavigationCommand: function (parameters) {
        const params = {};
        parameters.forEach((param, index) => {
            Object.keys(param).forEach((key) => {
                params[`CMD${index}_${key}`] = param[key];
            });
        });

        return params;
    },
    request: async function (commandName, parameters, config, debug, authorization) {
        /** LOG */
        if (this.isDebug() || debug) {
            log.logInfo(`\n[REQUEST] - ${commandName}`);
            log.logDefault('Parameters:');
            log.logDefault(parameters);
            log.logDefault(this.getHTTPUrl());
        }
        /** LOG */
        const isTypeNavigation = config.type && config.type === 'navigation';
        const isTypeFile = config.type && config.type === 'file';
        const isTypeAuthenticate = config.type && config.type === 'auth';

        if (isTypeFile) {
            config.parser = 'file';
            parameters.COMMAND = commandName;
        } else if (isTypeNavigation) {
            parameters[0].COMMAND = commandName;
            parameters = this.getParametersNavigationCommand(parameters);
            config.prependCMD = false;
        } else if (isTypeAuthenticate) {
            const { value: auth } = await alert.showCustomAlert({ title: config.customMessage || i18n.t('CONFIRM_PASSWORD') });
            
            if (authorization.password == null) {
                authorization = { username: auth.username || '', password: auth.password || ''};
            }

            if (config.prependCMD !== false) {
                parameters.COMMAND = commandName;
            }
        } else {
            parameters.COMMAND = commandName;
        }
        config.debug = debug;

        const formData = this.getFormData(parameters, config);
        let requestConfig = {
            method: 'post',
            url: this.getHTTPUrl(),
            data: formData,
            config: this.options.requestHeader,
        };

        if (authorization.password != null) {
            requestConfig.auth = authorization;
        } else if (localStorage.getItem('token') && commandName !== 'Login') {
            requestConfig.headers = { 'Authorization': localStorage.getItem('token') };
        }

        if (isTypeFile) {
            requestConfig = { ...requestConfig, responseType: 'blob' };
        }

        /** LOG */
        if (this.isDebug() || debug) {
            log.logDefault('FormData:');
            const aux = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const entry of formData.entries()) {
                aux[entry[0]] = entry[1];
            }
            log.logDefault(aux);
            log.separator();
        }
        /** LOG */
        return this.callCommand(commandName, parameters, config, requestConfig);
    },
    callCommand: function (commandName, parameters, config, requestConfig) {
        const isTypeFile = config.type && config.type === 'file';
        const isTypeAuthenticate = config.type && config.type === 'auth';
        
        return new Promise((resolve, reject) => {
            events.updateRequests(++this.runningRequests);
            axios(requestConfig).then((result) => {
                /** LOG */
                if (this.isDebug() || config.debug) {
                    // log.logInfo(`\n[RESPONSE] - ${commandName}`);
                    log.logDefault(result);
                    log.separator();
                }
                /** LOG */
                events.updateRequests(--this.runningRequests);

                if (commandName === 'Logout') {
                    localStorage.removeItem('token');
                    events.toggleLogin(true);
                }

                if (isTypeFile) {
                    let resultBlob = result.data;
                    if (resultBlob.type === 'application/json') {
                        resultBlob.text().then(blobTxt => {
                            let jsonResponse = JSON.parse(blobTxt);
                            this.handleResult(jsonResponse, config.silent, isTypeAuthenticate);
                            resolve(jsonResponse);
                        });
                    } else {
                        resolve(resultBlob.size ? URL.createObjectURL(new Blob([resultBlob])) : null);
                    }
                } else if (this.handleResult(result, config.silent, isTypeAuthenticate) > 0) {
                    if (commandName === 'Login') {
                        localStorage.setItem('token', result.data.RAIZ.AUTH_TOKEN || null);
                        // translate.getServerLocaleMessages();
                        // events.toggleLogin(false);
                    }

                    resolve(result.data);
                } else {
                    /** LOG */
                    if (this.isDebug() || config.debug) {
                        log.logDefault(result.data);
                        log.logDefault(this.getHTTPUrl());
                        log.logError('[REJECT]');
                    }
                    /** LOG */
                    reject(result.data);
                }
            }).catch((error) => {
                /** LOG */
                if (this.isDebug() || config.debug) {
                    // log.logError(`\n[RESPONSE] - ${commandName}`);
                    log.logDefault(this.getHTTPUrl());
                    log.logDefault(error);
                    log.logError('[ERROR]');
                }
                /** LOG */
                events.updateRequests(--this.runningRequests);

                reject(error);
            });
        });
    },
};

export default request;
