const searchDataProvider = {
    model: {
        prop: 'value',
    },
    props: {
        entity: {
            type: Object,
            default: function () {
                return {};
            },
        },
        entityParams: {
            type: Object,
            default: function () {
                return {};
            },
        },
        requireParams: {
            type: Array,
            default: function () {
                return [];
            },
        },
        clearProvider: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        _autoSelect: {
            type: Boolean,
            default: false,
        },
        _disableUserPreference: {
            type: Boolean,
            default: false,
        },
        value: {
            type: null,
            default: null,
        },
        items: {
            type: Array,
            default: undefined,
        },
        _disabledClearNotFound: {
            type: Boolean,
            default: false,
        },
        _minToSearch: {
            type: Number,
            required: false,
            default: undefined,
        },
        _holdSelectedItems: {
            type: Boolean,
            default: false,
        },
        _savedValue: {
            type: Object,
            default: undefined
        },
        _iconColor: {
            type: String,
            default: null,
        }
    },
    data: function () {
        return {
            search: '',
            loading: false,
            loadedData: [],
            valueLazy: this.value,
            multiple: this.$attrs.multiple || this.$attrs.multiple === '',
            lazySearch: {
                id: null,
                searched: false,
            },
        };
    },
    computed: {
        dataProvider: function () {
            if (!this.entity) {
                // Nenhuma entity utilizada, o provider funciona apenas com itens carregados externamente
                return this.items;
            }

            let data = [];
            if (this._savedValue) {
                data.push(this._savedValue);
            }
            if (this.multiple && this._holdSelectedItems && this.valueLazy != null) {
                data = data.concat(this.valueLazy);
            }
            data = data.concat(this.loadedData);

            return data;
        },
        itemKey: function () {
            return this.$attrs["item-value"] ? this.$attrs["item-value"] : this.entity.ID_FIELD_NAME;
        },
        itemKeyDesc: function () {
            return this.$attrs["item-text"] ? this.$attrs["item-text"] : this.entity.DESCRIPTION_FIELD_NAME;
        },
        minToSearch: function () {
            if (this._minToSearch) {
                return this._minToSearch;
            }
            return this.entity.MIN_TO_SEARCH || 0;
        },
        hasMinToSearch: function () {
            return (this.search || '').length >= this.minToSearch;
        },
        valueId: function () {
            if (this.valueLazy != null) {
                if (typeof this.valueLazy === 'object' && Object.keys(this.$attrs).includes('return-object') && this.$attrs['return-object'] !== false) {
                    return this.valueLazy[this.itemKey];
                }
                return this.valueLazy;
            }
            return null;
        },
        itemDesc: function () {
            if (this.valueId) {
                const item = this.dataProvider.find(item => String(item[this.itemKey]) === String(this.valueId));
                if (typeof this.itemKeyDesc === 'function') {
                    return (item && this.itemKeyDesc(item)) || '';
                }
                return (item && item[this.itemKeyDesc]) || '';
            }
            return '';
        },
        searchComputed: {
            get: function () {
                return this.search && String(this.search);
            },
            set: function (value) {
                this.search = value;
            },
        },
        // a-combobox can allow textual input without selection in the data provider, like the a-text-field. This will
        // verify when the value is in this state, so it doesn't send it to the command as an id
        isValueComboboxText: function () {
            return this.$options._componentTag === 'AComboboxCustom' && typeof this.value === 'string';
        },
        emptyProvider: function () {
            return this.dataProvider.length === 0;
        },
        isValueNull: function () {
            return !this.value || (typeof this.value === 'object' && !this.value[this.itemKey]);
        },
        preventSearch: function () {
            return (this.isValueNull || this.multiple) && !this.hasMinToSearch;
        },
    },
    watch: {
        search: {
            handler: function (val, oldVal) {
                if (this.minToSearch === 0 && this.dataProvider.length > 0) {
                    return;
                }

                val = val || '';
                oldVal = oldVal || '';

                if (!this.$_aura.isEqual(oldVal, val) && (val !== this.itemDesc || !this.valueLazy) && this.hasMinToSearch) {
                    if (this.lazySearch.id) {
                        clearTimeout(this.lazySearch.id);
                        this.lazySearch.id = null;
                    }

                    this.lazySearch.id = setTimeout(async () => {
                        await this.getData(val);
                    }, 300);
                }
            },
            immediate: false,
        },
        entityParams: {
            handler: function (val, oldVal) {
                if (!this.$_aura.isEqual(val, oldVal)) {
                    this.getData();
                }
            },
            deep: true,
        },
        entity: {
            handler: function (val, oldVal) {
                if (!this.$_aura.isEqual(val, oldVal)) {
                    this.getData();
                }
            },
            deep: true,
        },
        value: {
            handler: async function (val, oldVal) {
                if (!this.$_aura.isEqual(val, oldVal)) {
                    this.search = '';

                    if (((val !== undefined && val !== null) || (val && Array.isArray(val) && val.length)) && this.entity.SAVE_USER_SELECTION_KEY && !this._disableUserPreference) {
                        this.updateUserValuePreference(val);
                    }
                    //Caso tenha id, porém não tenha dataprovider, permitimos carregar o campo por ID
                    if (!this.multiple && !this.$util.isStringEmpty(val) && this.emptyProvider && this.minToSearch > 0) {
                        await this.getData();

                        if (val && !this.existInProvider(val) && !this._disabledClearNotFound) {
                            return;
                        }
                    }
                    this.valueLazy = val;
                    this.verifyIfValueLazyExistsInLoadedData();
                }
            },
            deep: true,
            immediate: false,
        },
        valueLazy: {
            handler: function (val, oldVal) {
                if (!val) {
                    this.search = '';
                }
                if (!this.$_aura.isEqual(val, oldVal)) {
                    this.$emit('input', val);
                }
            },
            deep: true,
        },
        dataProvider: {
            deep: true,
            handler: function (val, oldVal) {
                if (!this.$_aura.isEqual(val, oldVal)) {
                    this.$emit('update:items', val);
                }
            },
        },
        '$attrs.multiple': {
            handler: function (val, oldVal) {
                if (!this.$_aura.isEqual(val, oldVal)) {
                    return this.$attrs.multiple || this.$attrs.multiple === '';
                }
            }
        }
    },
    created: function () {
        this.selectUserPreferences();
    },
    mounted: function () {
        this.getData();
    },
    methods: {
        customFilterFunction: function (item, queryText, itemText) {
            let itemClear = itemText.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            let queryClear = queryText.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            return itemClear.indexOf(queryClear) > -1;
        },
        existInProvider: function (val) {
            if (typeof val === 'object') {
                return this.dataProvider.some(it => it[this.entity.ID_FIELD_NAME] === val[this.entity.ID_FIELD_NAME]);
            } else {
                return this.dataProvider.some(it => it[this.entity.ID_FIELD_NAME] === val);
            }
        },
        getParameters: function () {
            const entityParams = this.entityParams || {};
            const parameters = this.entity.PARAMETERS || {};
            return { ...parameters, ...entityParams };
        },
        verifyRequireParams: function (params) {
            const { length } = this.requireParams;
            for (let i = 0; i < length; i++) {
                const value = params[this.requireParams[i]];
                if (value === null || value === undefined || (typeof value === 'string' && !value)) {
                    return true;
                }
            }
            return false;
        },
        compareValue: function (val) {
            return this.dataProvider.find(value => {
                if (this.$attrs['value-comparator']) {
                    return this.$attrs['value-comparator'](value[this.itemKey], val);
                }
                return String(value[this.itemKey]) === String(val);
            })
        },
        getData: async function (value) {
            if (this.preventSearch) {
                return;
            }

            const params = this.$_aura.cloneDeep(this.getParameters());
            if (this.entity.SEARCH_FIELD) {
                params[this.entity.SEARCH_FIELD] = value || this.search || '';
            }
            if (this.entity.ID_FIELD_NAME && this.value && this.minToSearch > 0 && !this.isValueComboboxText && this.$util.isStringEmpty(this.search)) {
                let paramValue = this.value;
                if (Object.keys(this.$attrs).includes('return-object') || (typeof paramValue === 'object')) {
                    paramValue = paramValue[this.entity.ID_FIELD_NAME]
                }
                params[this.entity.ID_FIELD_NAME] = paramValue;
            }

            if (this.verifyRequireParams(params)) {
                if (this.clearProvider) {
                    this.loadedData = [];
                    this.valueLazy = null;
                }
                return;
            }

            this.loadedData = [];
            if (!this.entity.JSON_PROVIDER) {

                this.loading = true;
                try {
                    const resolve = await this.$_aura.callCommand(this.entity.COMMAND_NAME, params);
                    const dataProvider = this.entity.DATA_PROVIDER_FIELD || {};
                    const jsonTraversal = dataProvider.split('.');
                    let finalData = resolve.RAIZ;

                    for (let i = 0; i < jsonTraversal.length; i++) {
                        finalData = finalData[jsonTraversal[i]];
                        if (finalData === null) {
                            return;
                        }
                    }
                    finalData = this.$_aura.getArray(() => finalData);

                    await this.processProvider(finalData);

                    if (this.multiple) {
                        if (this.valueLazy != null && !this._holdSelectedItems) {
                            this.valueLazy = this.valueLazy.filter((item) => this.compareValue(item));
                        }
                    } else if (!this._disabledClearNotFound && this.valueLazy != null && !compareValue(this.valueId)) {
                        this.valueLazy = null;
                    }

                } catch (reject) {
                }
                finally {
                    this.loading = false;
                }
            } else {
                let finalData = [];
                const providerLength = this.entity.JSON_PROVIDER.length;
                for (let i = 0; i < providerLength; i++) {
                    finalData.push({
                        [this.itemKey]: this.entity.JSON_PROVIDER[i][this.itemKey],
                        [this.entity.DESCRIPTION_FIELD_NAME]: this.$t(this.entity.JSON_PROVIDER[i][this.entity.DESCRIPTION_FIELD_NAME]),
                    });
                }
                await this.processProvider(finalData);
            }
            this.verifyIfValueLazyExistsInLoadedData();
        },
        verifyIfValueLazyExistsInLoadedData: function () {
            // Verifica se o valueLazy existe em loadedData, caso não exista valueLazy fica como null
            if (this.entity?.ID_FIELD_NAME && this.loadedData?.length && this.valueLazy && this.valueLazy[this.entity.ID_FIELD_NAME] && this.$util.isStringEmpty(this.search)) {
                if (!this.loadedData.some(data => data[this.entity.ID_FIELD_NAME] && data[this.entity.ID_FIELD_NAME] === this.valueLazy[this.entity.ID_FIELD_NAME])) {
                    this.valueLazy = null;
                }
            }
        },
        processProvider: async function (data) {
            if (this.entity.CUSTOM_FUNCTION) {
                data = await this.entity.CUSTOM_FUNCTION(data);
            }
            this.loadedData = data;

            if (this.entity.SAVE_USER_SELECTION_KEY) {
                this.selectUserPreferences();
            } else if (this._autoSelect) {
                if (Object.keys(this.$attrs).includes('return-object') && this.$attrs['return-object'] !== false) {
                    this.emitInput(this.dataProvider[0]);
                } else {
                    this.emitInput(this.dataProvider[0][`${this.itemKey}`]);
                }
            }
            this.$emit('loaded', this.dataProvider);
        },
        openEditDialog: function (util) {
            let { EDIT_DIALOG } = this.entity;
            EDIT_DIALOG = EDIT_DIALOG || {};
            const props = {};

            if (EDIT_DIALOG.params) {
                const isObject = typeof this.valueLazy === 'object';

                Object.keys(EDIT_DIALOG.params).forEach((key) => {
                    if (isObject) {
                        props[EDIT_DIALOG.params[key]] = this.valueLazy && this.valueLazy[key] ? this.valueLazy[key] : undefined;
                    } else {
                        props[EDIT_DIALOG.params[key]] = this.valueLazy;
                    }
                });
            }

            EDIT_DIALOG.pageConfig = { ...EDIT_DIALOG.pageConfig };
            EDIT_DIALOG.pageConfig.events = [...(EDIT_DIALOG.pageConfig.events || [{}]), { name: 'reload-provider', callback: this.refreshProvider }];
            EDIT_DIALOG.pageConfig.props = { ...(EDIT_DIALOG.pageConfig.props || {}), ...props };
            const page = { ...EDIT_DIALOG.page };
            if (typeof page.name === 'object') {
                page.name = this.valueLazy == null ? EDIT_DIALOG.page.name.create : EDIT_DIALOG.page.name.edit;
            }

            this.$nextTick(() => {
                util.openPage(page, EDIT_DIALOG.pageConfig);
            });
        },
        refreshProvider: function () {
            this.$emit('refresh');
            this.getData(this.value);
        },
        selectUserPreferences: function () {
            if (localStorage.getItem('preferences') && !this._disableUserPreference) {
                const preferences = JSON.parse(localStorage.getItem('preferences'));
                const preferencesGlobal = JSON.parse(localStorage.getItem('preferencesGlobal'));
                if (preferences && preferences.values || (preferencesGlobal && preferencesGlobal.values)) {
                    JSON.parse(preferences.values).forEach((item) => {
                        if (item.key === this.entity.SAVE_USER_SELECTION_KEY && item.value) {
                            if (Array.isArray(item.value)) {
                                const preferenceGlobal = preferencesGlobal.values ? JSON.parse(preferencesGlobal.values).find(value => value.key === this.entity.SAVE_USER_SELECTION_KEY) : null;
                                if (preferenceGlobal || item.value.length === 1) {
                                    const value = item.value.length === 1 ? item.value[0] : item.value.find(element => element === preferenceGlobal.value);
                                    if (value || item.value.length === 1) {
                                        this.emitInput(value);
                                    }
                                }
                            } else {
                                this.emitInput(item.value);
                            }
                        }
                    });
                }
            }
        },
        updateUserValuePreference: function (val) {
            const preferences = localStorage.getItem('preferences');
            if (!preferences) {
                return;
            }
            let jsPreferences = JSON.parse(preferences);
            const { values } = jsPreferences;
            let jsValues = [];
            if (values) {
                jsValues = JSON.parse(values);
            }

            let contains = false;
            if (Object.keys(this.$attrs).includes('return-object') && this.$attrs['return-object'] !== false && val) {
                val = val[this.itemKey];
            }
            jsValues.forEach((obj) => {
                if (obj.key === this.entity.SAVE_USER_SELECTION_KEY) {
                    obj.value = val;
                    contains = true;
                }
            });
            if (!contains) {
                jsValues.push({ key: this.entity.SAVE_USER_SELECTION_KEY, value: val });
            }
            jsPreferences = {
                ...jsPreferences,
                values: JSON.stringify(jsValues),
            };
            localStorage.setItem('preferences', JSON.stringify(jsPreferences));
        },
        emitInput: function (value) {
            if (this.multiple) {
                this.valueLazy = [value];
            } else {
                this.valueLazy = value;
            }
        },
        compareItems(value, option) {
            if (value !== null && option !== null) {
                if (!(typeof value === 'object') && String(option) === String(value)) {
                    return true
                }
            }
            return false
        }
    },
};

export default searchDataProvider;
