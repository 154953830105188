import Vue from 'vue';
import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
const { $autocomplete, $select, $util, $moment, } = Vue.prototype;
export class GetRegisteredAssets extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPontosCadastradosPeriodo',
            type: 'SEARCH',
            node: 'PONTOS',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('WORKSITE'),
                        entity: $autocomplete.worksite,
                        'entity-params': { ATIVO: 1 },
                        multiple: true,
                    },
                }),
                status: new Field({
                    name: 'STATUS',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('STATUS'),
                        entity: $autocomplete.registeredAssetStatus,
                    },
                    value: '1',
                }),
                initDate: new Field({
                    name: 'PERIODO_INICIO',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('INITIAL_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                    value: $moment().subtract(1, 'months').format('DD/MM/YYYY'),
                }),
                initTime: new Field({
                    name: 'HORA_INICIAL',
                    component: 'ATimePicker',
                    props: {
                        label: i18n.t('INITIAL_TIME'),
                        'text-field': true,
                        'return-serve': true,
                    },
                }),
                endDate: new Field({
                    name: 'PERIODO_FIM',
                    component: 'ADatePicker',
                    parser: $util.relativeDateParser,
                    props: {
                        label: i18n.t('ENDING_DATE'),
                        'text-field': true,
                        'return-serve': true,
                        'enable-relative-picker': true,
                    },
                }),
                endTime: new Field({
                    name: 'HORA_FINAL',
                    component: 'ATimePicker',
                    props: {
                        label: i18n.t('ENDING_TIME'),
                        'text-field': true,
                        'return-serve': true,
                    },
                }),
                assetTypeId: new Field({
                    name: 'ID_TIPO_PONTO_SERVICO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ASSET_TYPE'),
                        entity: $autocomplete.assetType,
                        clearable: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.assetTypeId.props['entity-params'] = {
                                    ...fields.assetTypeId.props['entity-params'],
                                    IDS_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                userCompanyId: new Field({
                    name: 'ID_EMPRESA_USUARIA',
                    component: 'AAutocomplete',
                    props: {
                        entity: $autocomplete.userCompany,
                        label: i18n.t('USER_COMPANY'),
                        clearable: true,
                        edit: true,
                        '_disable-auto-select': true,
                    },
                }),
                allowedLocationId: new Field({
                    name: 'ID_AGRUPAMENTO_LOCALIZACAO',
                    component: 'ASelect',
                    props: {
                        label: i18n.t('ALLOWED_LOCATION'),
                        clearable: true,
                        entity: $select.groupingLocation,
                        'entity-params': {},
                        '_disable-auto-select': true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_EMPRESA_USUARIA',
                            handler: (fields) => {
                                fields.allowedLocationId.props['entity-params'] = {
                                    ID_EMPRESA_USUARIA: fields.userCompanyId.value,
                                };
                            },
                        }],
                }),
                teamId: new Field({
                    name: 'ID_EQUIPE',
                    props: {},
                }),
                alterationTeamId: new Field({
                    name: 'ID_EQUIPE_ALTERACAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('ALTERATION_TEAM'),
                        entity: $select.team,
                        clearable: true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                validationTeamId: new Field({
                    name: 'ID_EQUIPE_VALIDACAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('VALIDATION_TEAM'),
                        entity: $select.team,
                        clearable: true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                installationTeamId: new Field({
                    name: 'ID_EQUIPE_INSTALACAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('INSTALLATION_TEAM'),
                        entity: $select.team,
                        clearable: true,
                    },
                    dependsOn: [{
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.teamId.props.disabled = !fields.worksiteId.value;
                            },
                        }],
                }),
                search: new Field({
                    name: 'PALAVRA',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('SEARCH'),
                    },
                }),
                onlyWithoutPosition: new Field({
                    name: 'SOMENTE_SEM_POSICAO',
                    component: 'ACheckbox',
                    props: {
                        label: i18n.t('ONLY_WITHOUT_POSITION'),
                    },
                    parser: $util.booleanToNumber,
                }),
                attributeFilter: new Field({
                    name: 'ATRIBUTOS',
                    component: () => import('@components/multipleAutocomplete/MultipleAutocomplete.vue'),
                    parser: (value) => {
                        if (value) {
                            const mapped = value.map((elem) => elem.ID_ATRIBUTO);
                            return mapped.join(',');
                        }
                        return '';
                    },
                    props: {
                        _entity: $autocomplete.attribute,
                        '_entity-params': { SGI: 1, SOMENTE_ATRIBUTOS_PONTO: 1 },
                        _label: i18n.t('ATTRIBUTES'),
                        _deletableChips: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_TIPO_PONTO_SERVICO',
                            handler: (fields) => {
                                fields.attributeFilter.props['entity-params'] = {
                                    SGI: 1,
                                    ID_TIPO_PONTO_SERVICO: fields.assetTypeId.value,
                                    SOMENTE_ATRIBUTOS_PONTO: 1,
                                };
                            },
                        },
                    ],
                }),
                minDistance: new Field({
                    name: 'DISTANCIA_MINIMA',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MIN_DISTANCE'),
                        type: 'number',
                    },
                    dependsOn: [{
                            fieldName: 'SOMENTE_SEM_POSICAO',
                            handler: (fields) => {
                                fields.minDistance.props.disabled = !!fields.onlyWithoutPosition.value;
                                if (fields.onlyWithoutPosition.value) {
                                    fields.minDistance.value = null;
                                }
                            },
                        }],
                }),
                maxDistance: new Field({
                    name: 'DISTANCIA_MAXIMA',
                    component: 'ATextField',
                    props: {
                        label: i18n.t('MAX_DISTANCE'),
                        type: 'number',
                    },
                    dependsOn: [{
                            fieldName: 'SOMENTE_SEM_POSICAO',
                            handler: (fields) => {
                                fields.maxDistance.props.disabled = !!fields.onlyWithoutPosition.value;
                                if (fields.onlyWithoutPosition.value) {
                                    fields.maxDistance.value = null;
                                }
                            },
                        }],
                }),
                areaId: new Field({
                    name: 'ID_AREA',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('AREA'),
                        entity: $select.area,
                        'clear-provider': true,
                        multiple: true,
                        '_prepend-item-select-all': true,
                        '_disable-auto-select': true,
                    },
                    parser: $util.arrayToString,
                }),
                cityId: new Field({
                    name: 'ID_MUNICIPIO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('CITY'),
                        entity: $autocomplete.cities,
                        'entity-params': { SOMENTE_ATIVOS: 1 },
                        'require-params': ['ID_PARQUE_SERVICO'],
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.cityId.props.disabled = !fields.worksiteId.value;
                                fields.cityId.props['entity-params'] = {
                                    SOMENTE_ATIVOS: 1,
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                    ],
                }),
                regionId: new Field({
                    name: 'ID_REGIAO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('REGION'),
                        entity: $autocomplete.region,
                        'entity-params': {},
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.regionId.props.disabled = !fields.worksiteId.value;
                                fields.regionId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_MUNICIPIO',
                            handler: (fields) => {
                                fields.regionId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    ID_MUNICIPIO: fields.cityId.value,
                                };
                            },
                        },
                    ],
                }),
                districtId: new Field({
                    name: 'ID_BAIRRO',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('DISTRICT'),
                        entity: $autocomplete.district,
                        'entity-params': {},
                        clearable: true,
                        'clear-provider': true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_PARQUE_SERVICO',
                            handler: (fields) => {
                                fields.districtId.props.disabled = !fields.worksiteId.value;
                                fields.districtId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_MUNICIPIO',
                            handler: (fields) => {
                                fields.districtId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    ID_MUNICIPIO: fields.cityId.value,
                                    ID_REGIAO: fields.regionId.value,
                                };
                            },
                        },
                        {
                            fieldName: 'ID_REGIAO',
                            handler: (fields) => {
                                fields.districtId.props['entity-params'] = {
                                    ID_PARQUE_SERVICO: fields.worksiteId.value,
                                    ID_MUNICIPIO: fields.cityId.value,
                                    ID_REGIAO: fields.regionId.value,
                                };
                            },
                        },
                    ],
                }),
                measuredEstimated: new Field({
                    name: 'MED_EST',
                    component: 'AAutocomplete',
                    props: {
                        label: i18n.t('MEASURED_ESTIMATED'),
                        entity: $autocomplete.measuredEstimated,
                    },
                }),
                attributesFilter: new Field({
                    name: 'FILTRO_ATRIBUTOS',
                    component: () => import('@components/attribute/AttributeFilter.vue'),
                    parser: $util.attributesToString,
                    props: {
                        _assetTypeId: null,
                        _sgi: 1,
                        _filter: true,
                    },
                    dependsOn: [
                        {
                            fieldName: 'ID_TIPO_PONTO_SERVICO',
                            handler: (fields) => {
                                fields.attributesFilter.props._assetTypeId = fields.assetTypeId.value && Number(fields.assetTypeId.value);
                            },
                        },
                    ],
                }),
                excelReport: new Field({
                    name: 'RELATORIO_EXCEL',
                    value: 0,
                }),
                version: new Field({
                    name: 'VERSAO',
                    value: 2,
                }),
                situation: new Field({
                    name: 'SITUATION',
                    value: null,
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
